<template>
  <Step :step="5" />

  <div class="info container page">
    <div class="box">
      <div class="form-group">
        <HeaderStep :title="$t('step3.title')" :step="2" id="s1"></HeaderStep>

        <div class="form-group-title">{{ $t("step6.title") }}</div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.isNew }" id="s2">
            <label class="form-field">
              {{ $t("info.isNew") }}
              <span v-if="error.isNew">{{ error.isNew }}</span>
            </label>
            <div class="q-item">
              <div class="q-answer">
                <span
                  :class="{ active: detail.is_new }"
                  @click="detail.is_new = 1"
                >
                  {{ $t("yes") }}
                </span>
                <span
                  :class="{ active: !detail.is_new && detail.is_new !== null }"
                  @click="detail.is_new = 0"
                >
                  {{ $t("no") }}
                </span>
              </div>
            </div>
          </div>

          <div class="form-item" :class="{ error: error.vehicleCert }" id="s3">
            <label class="form-field">
              {{ $t("info.vehicleCert") }}
              <span v-if="error.vehicleCert">{{ error.vehicleCert }}</span>
            </label>

            <div
              class="form-input"
              v-show="!confirm"
              @click="
                confirm = true;
                tips = true;
              "
            >
              {{
                !detail.vehicle_cert_filename
                  ? $t("step5.upload")
                  : detail.vehicle_cert_filename
              }}
            </div>

            <file-upload
              v-model="files"
              :post-action="api"
              ref="upload"
              @input-file="inputFile"
              @input-filter="inputFilter"
              v-show="confirm"
            >
              <div class="form-input" v-if="!detail.vehicle_cert_filename">
                {{ $t("step5.upload") }}
              </div>
              <div class="form-input" v-else>
                {{ detail.vehicle_cert_filename }}
              </div>
            </file-upload>
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.brand }" id="s4">
            <label class="form-field">
              {{ $t("info.brand") }}
              <span v-if="error.brand">{{ error.brand }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.brand"
              @blur="check('brand')"
            />
          </div>

          <div class="form-item" :class="{ error: error.model }" id="s5">
            <label class="form-field">
              {{ $t("info.model") }}
              <span v-if="error.model">{{ error.model }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.model"
              @blur="check('model')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.chassisNum }" id="s6">
            <label class="form-field">
              {{ $t("info.chassisNum") }}
              <span v-if="error.chassisNum">{{ error.chassisNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.chassis_num"
              @blur="check('chassisNum')"
            />
          </div>

          <div class="form-item" :class="{ error: error.engineNum }" id="s7">
            <label class="form-field">
              {{ $t("info.engineNum") }}
              <span v-if="error.engineNum">{{ error.engineNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.engine_num"
              @blur="check('engineNum')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.displacement }" id="s8">
            <label class="form-field">
              {{ $t("info.displacement") }}
              <span v-if="error.displacement">{{ error.displacement }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.displacement"
              @blur="check('displacement')"
              type="number"
            />
          </div>

          <div class="form-item" :class="{ error: error.bodyType }" id="s9">
            <label class="form-field">
              {{ $t("info.bodyType") }}
              <span v-if="error.bodyType">{{ error.bodyType }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.body_type"
              @blur="check('bodyType')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.quality }" id="s10">
            <label class="form-field">
              {{ $t("info.quality") }}
              <span v-if="error.quality">{{ error.quality }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.quality"
              @blur="check('quality')"
              type="number"
            />
          </div>
          <div class="form-item" :class="{ error: error.carNum }" id="s11">
            <label class="form-field">
              {{ $t("info.carNum") }}
              <span v-if="error.carNum">{{ error.carNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.car_num"
              @blur="check('carNum')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.year }" id="s12">
            <label class="form-field">
              {{ $t("info.year") }}
              <span v-if="error.year">{{ error.year }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.year"
              :options="options.years"
              @select="check('year')"
              :allowEmpty="false"
            />
          </div>
          <div class="form-item" :class="{ error: error.siteNum }">
            <label class="form-field">
              {{ $t("info.siteNum") }}
              <span v-if="error.siteNum">{{ error.siteNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.site_num"
              @blur="check('siteNum')"
              type="number"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">{{ $t("info.back") }}</button>
      <button class="btn btn-next" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>

  <Loading :message="$t('ocring')" v-if="ocrLoading" />

  <Modal v-model:visible="tips" :title="$t('tips')">
    <p>為達到最佳文字辨識效果，請注意以下幾點：</p>
    <ul>
      <li>請維持文件完整性，不能切角或切邊／反光／模糊不清／遮住個人資料</li>
      <li>拍照之前要先對焦-手機：點一下螢幕、相機：輕按快門</li>
      <li>將拍攝物直立，可避免因光源所造成的反白</li>
      <li>拍照照片最低解析度為 1280x1024，掃描請設定 300 DPI 以上</li>
    </ul>
  </Modal>
</template>
<script>
import Step from "../../components/Step";
import Loading from "../../components/Loading";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";
import Multiselect from "@vueform/multiselect";
import Modal from "../../components/Modal";

import FileUpload from "vue-upload-component";

import "@vueform/multiselect/themes/default.css";

import config from "../../config";

import { scroller } from "vue-scrollto/src/scrollTo";

const scrollTo = scroller();

export default {
  name: "Step6",

  components: {
    Step,
    Multiselect,
    HeaderStep,
    FileUpload,
    Loading,
    Modal,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      api: config.api + "file",

      ocrLoading: false,

      detail: {
        type: "汽車全保",
        brand: "KIA",
        model: "Sorento",
        petrol: "",
        car_type: "",
        money: "",
        quality: "",
        year: "",
        discount: "",
        limit: "",
        date: "",
        old_note: "",
        finance_company: "",
        old_company: "",
        not_pay_years: "",
        car_old_num: "",
        note_num: "",
        is_new: null,
        chassis_num: "",
        engine_num: "",
        displacement: "",
        body_type: "",
        car_num: "",
        site_num: "",
        vehicle_cert: "",
        vehicle_cert_filename: "",
      },

      options: {
        years: (() => {
          let years = [];
          for (let i = new Date().getFullYear(); i > 1990; i--) {
            years.push(i + "");
          }
          return years;
        })(),
      },

      error: {
        isNew: false,
        vehicleCert: false,
        brand: false,
        model: false,
        chassisNum: false,
        engineNum: false,
        displacement: false,
        bodyType: false,
        quality: false,
        carNum: false,
        year: false,
        siteNum: false,
      },

      files: [],

      tips: false,
      confirm: false,
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache.detail);
  },

  methods: {
    check(field) {
      const self = this;

      let rules = {
        isNew: {
          model: this.detail.is_new,
          ref: "#s1",
          message: this.$t("info.verify.isNew"),
          fn: "require",
        },
        vehicleCert: {
          model: this.detail.vehicle_cert,
          ref: "#s2",
          message: this.$t("info.verify.vehicleCert"),
          before() {
            return !self.detail.is_new;
          },
          fn: "require",
        },
        brand: {
          model: this.detail.brand,
          ref: "#s3",
          message: this.$t("info.verify.brand"),
          fn: "require",
        },
        model: {
          model: this.detail.model,
          ref: "#s4",
          message: this.$t("info.verify.model"),
          fn: "require",
        },
        chassisNum: {
          model: this.detail.chassis_num,
          ref: "#s5",
          message: this.$t("info.verify.chassisNum"),
          fn: "alphaDash",
        },
        engineNum: {
          model: this.detail.engine_num,
          ref: "#s6",
          message: this.$t("info.verify.engineNum"),
          fn: "alphaDash",
        },
        displacement: {
          model: this.detail.displacement,
          ref: "#s7",
          message: this.$t("info.verify.displacement"),
          fn: "number",
        },
        bodyType: {
          model: this.detail.body_type,
          ref: "#s8",
          message: this.$t("info.verify.bodyType"),
          fn: "alphaDash",
        },
        quality: {
          model: this.detail.quality,
          ref: "#s9",
          message: this.$t("info.verify.quality"),
          fn: "number",
        },
        carNum: {
          model: this.detail.car_num,
          ref: "#s10",
          message: this.$t("info.verify.carNum"),
          fn: "alphaDash",
        },
        year: {
          model: this.detail.year,
          ref: "#s11",
          message: this.$t("info.verify.year"),
          fn: "number",
        },
        siteNum: {
          model: this.detail.site_num,
          ref: "#s12",
          message: this.$t("info.verify.siteNum"),
          fn: "number",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (rule.before && !rule.before()) {
          this.error[key] = false;
          continue;
        }

        if (!util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    prev() {
      this.$router.push("/step5");
    },

    next() {
      if (!this.check()) {
        return;
      }

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setCache");

      if (this.$route.query.confirm) {
        this.$router.push("/step8");
      } else {
        this.$router.push("/step7");
      }
    },

    async inputFile(newFile, oldFile) {
      // Upload progress
      if ((newFile && newFile.progress) || (oldFile && oldFile.progress)) {
        this.detail.vehicle_cert_filename = this.$t("step5.uploading");
      } else if (newFile && !newFile.success) {
        this.detail.vehicle_cert_filename = this.$t("error.netword");
        return false;
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }

      const data = newFile.response.data;

      if (!data) {
        return;
      }

      this.detail.vehicle_cert_filename = data.origin_name;
      this.detail.vehicle_cert = data.save_name;

      this.ocrLoading = true;

      const ocrResp = await this.$fetch("ocr/vehicle?file=" + data.save_name);
      const ocrResult = ocrResp.data.data;

      this.ocrLoading = false;

      this.detail = Object.assign(this.detail, {
        car_num: ocrResult.RegistrationMark,
        body_type: ocrResult.BodyType,
        year: ocrResult.YearOfManufacture,
        brand: ocrResult.Make,
        model: ocrResult.Model,
        chassis_num: ocrResult.ClassisNo,
        engine_num: ocrResult.EngineNo,
        displacement: ocrResult.CylinderCapacity,
        quality: ocrResult.GrossWeight,
      });
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // 过滤不是图片后缀的文件
        if (!/\.(jpeg|jpe|jpg|png|pdf)$/i.test(newFile.name)) {
          alert(this.$t("error.file"));
          return prevent();
        }
      }

      this.$refs.upload.active = true;
    },
  },
};
</script>
<style scoped lang="less">
</style>