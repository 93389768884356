export default {
    state: {
        data: {
            sign: "",

            amount: 0,

            detail: {
                type: "汽車全保",
                brand: "KIA",
                model: "Sorento",
                petrol: "",
                car_type: "",
                money: "",
                quality: "",
                year: "",
                discount: "",
                limit: "",
                date: "",
                old_note: "",
                old_note_filename: "",
                finance_company: "",
                old_company: "",
                not_pay_years: "",
                car_old_num: "",
                note_num: "",
                is_new: null,
                vehicle_cert: "",
                vehicle_cert_filename: "",
                chassis_num: "",
                engine_num: "",
                displacement: "",
                body_type: "",
                car_num: "",
                site_num: "",
                setter_type: "person",

                owner: {
                    birth_day: "",
                    sex: "",
                    id: "",
                    hkid: "",
                    job: "",
                    address: "",
                    is_driver: 1,
                    driver_num: "",
                    driver: "",
                    driver_name: "",
                    years: "",
                    company_name: "",
                    biz_cert_num: "",
                    biz_cert: "",
                    biz_cert_filename: ""
                },
            },

            contact: {
                name: "",
                email: "",
                mobile: "",
                agree1: true,
                agree2: true,
                agree3: true,
            },

            info_question: {
                q1: null,
                q2: null,
                q3: null,
                q4: null
            },

            car_info_question: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
                q5: null
            },

            drivers: [],

            referer: null
        },
    },

    mutations: {
        ["detail/setData"](state, data) {
            state.data = Object.assign(state.data, data);
        },

        ["detail/setInfoQuestion"](state, data) {
            state.data.info_question = data;
        },

        ["detail/setCarInfoQuestion"](state, data) {
            state.data.car_info_question = data;
        },

        ["detail/setSignature"](state, signURL) {
            state.data.sign = signURL;
        },

        ["detail/setAmount"](state, amount) {
            state.data.amount = Number(amount.replace(/,/g, ""));
        },

        ["detail/setReferer"](state, referer) {
            state.data.referer = referer ? referer : "";
        },

        ["detail/setDetail"](state, detail) {
            state.data.detail = Object.assign(state.data.detail, detail);
        },

        ["detail/setDrivers"](state, drivers) {
            state.data.drivers = Object.assign(state.data.drivers, drivers);
        },

        ["detail/setContact"](state, contact) {
            state.data.contact = Object.assign(state.data.contact, contact);
        },

        ["detail/setCache"](state) {
            window.sessionStorage.setItem("detail", JSON.stringify(state.data));
        }
    },

    actions: {
        ["detail/getCache"]({ commit }) {
            const cache = window.sessionStorage.getItem("detail");
            if (!cache) {
                return false;
            }

            const data = JSON.parse(cache);
            commit("detail/setData", data);
        },

        ["detail/clearCache"]() {
            window.sessionStorage.removeItem("detail");
        }
    }
};
