<template>
  <Header />
  <router-view />
  <Footer />
</template>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  watch: {
    "$route.query.code"() {
      this.init();
    },
  },

  data() {
    return {
      code: null,
    };
  },

  mounted() {
    this.$store.dispatch("detail/getCache");
  },

  methods: {
    init() {
      const code = this.$route.query.code;
      if (code) {
        this.code = code;
        window.localStorage.setItem("code", code);
        this.getOrder();
      }
    },

    async getOrder() {
      let { data } = await this.$fetch(`order/error/${this.code}`);
      data = data.data;

      this.$store.commit("detail/setData", data.data);
      this.$store.dispatch("detail/getCache");

      this.$router.push("/");
    },
  },
};
</script>
<style>
</style>
