<template>
  <Step :step="9" />

  <div class="page container" id="Success">
    <div class="box">
      <HeaderStep :title="$t('step.step10')" :step="4"></HeaderStep>
      <div>
        <p>交易完成</p>
        <p>
          您的交易編號：
          <span>LPC-R{{ order.lpc_id }}</span>
        </p>
        <p>暫保單會傳送到您登記的電郵地址。</p>
        <p>
          如有任何問題，請致電服務熱線2375 3456或電郵至服務支援
          {{ supportMail }}。我們職員會盡快與閣下聯絡。
        </p>
        <p><a href="https://www.insurfun.com/" class="link">返回主頁</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";

export default {
  name: "Success",

  components: {
    Step,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      id: null,

      supportMail: "cssupport@insurfun.com",

      order: {
        payment: {
          trade_no: null,
        },
      },
    };
  },

  methods: {
    async init() {
      this.getSetting();

      this.info = Object.assign({}, this.detail);
      this.id = this.$route.params.id;

      const result = await this.$fetch(`order/${this.id}`);
      if (!result.isOk) {
        return;
      }

      const { order } = result.data.data;
      this.order = order;

      window.sessionStorage.removeItem("detail");
      window.localStorage.removeItem("code");
    },

    async getSetting() {
      const { data } = await this.$fetch("setting");
      const { email } = data.data;
      this.supportMail = email;
    },
  },
};
</script>
<style scoped lang="less">
#Success {
  .link {
    display: inline-block;
    width: 94px;
    height: 34px;
    border-radius: 5px;
    background: #848484;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    outline: none;
  }
}
</style>