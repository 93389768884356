<template>
  <Step :step="1" />
  <div class="info container page">
    <div class="box">
      <div class="form-group">
        <HeaderStep :title="$t('step1.title')" :step="1"></HeaderStep>

        <div class="form-group-title" id="s1">{{ $t("info.detail") }}</div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.type }" id="s2">
            <label class="form-field">
              {{ $t("info.type") }}
              <span v-if="error.type">{{ error.type }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.type"
              @blur="check('type')"
              readonly
            />
          </div>

          <div class="form-item" :class="{ error: error.brand }" id="s3">
            <label class="form-field">
              {{ $t("info.brand") }}
              <span v-if="error.brand">{{ error.brand }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.brand"
              @blur="check('brand')"
              readonly
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.model }" id="s4">
            <label class="form-field">
              {{ $t("info.model") }}
              <span v-if="error.model">{{ error.model }}</span>
            </label>
            <div class="model">
              <span>Sorento</span>
              <input
                class="form-input"
                v-model="detail.model"
                @blur="check('model')"
              />
            </div>
          </div>

          <div class="form-item" :class="{ error: error.petrol }" id="s5">
            <label class="form-field">
              {{ $t("info.petrol") }}
              <span v-if="error.petrol">{{ error.petrol }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.petrol"
              :options="options.petrol"
              @select="selectPetrol"
              :allowEmpty="false"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.carType }" id="s6">
            <label class="form-field">
              {{ $t("info.carType") }}
              <span v-if="error.carType">{{ error.carType }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.car_type"
              @blur="check('carType')"
              readonly
            />
          </div>

          <div class="form-item" :class="{ error: error.money }" id="s7">
            <label class="form-field">
              {{ $t("info.money") }}
              <span v-if="error.money">{{ error.money }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.money"
              @blur="check('money')"
              type="number"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.quality }" id="s8">
            <label class="form-field">
              {{ $t("info.quality") }}
              <span v-if="error.quality">{{ error.quality }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.quality"
              @blur="check('quality')"
              type="number"
            />
          </div>

          <div class="form-item" :class="{ error: error.year }" id="s9">
            <label class="form-field">
              {{ $t("info.year") }}
              <span v-if="error.year">{{ error.year }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.year"
              :options="options.years"
              @select="check('year')"
              :allowEmpty="false"
            />
            <input type="hidden" v-model="detail.year" ref="year" />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.discount }" id="s10">
            <label class="form-field">
              {{ $t("info.discount") }}
              <span v-if="error.discount">{{ error.discount }}</span>
            </label>

            <Multiselect
              v-if="detail.petrol == '柴油'"
              class="form-input"
              v-model="detail.discount"
              :options="options.discount1"
              @select="check('discount')"
              :allowEmpty="false"
            />
            <Multiselect
              v-else
              class="form-input"
              v-model="detail.discount"
              :options="options.discount2"
              @select="check('discount')"
              :allowEmpty="false"
            />
          </div>

          <div class="form-item" :class="{ error: error.limit }" id="s11">
            <label class="form-field">
              {{ $t("info.limit") }}
              <span v-if="error.limit">{{ error.limit }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.limit"
              :options="options.limit"
              @select="check('limit')"
              :allowEmpty="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";
import Multiselect from "@vueform/multiselect";

import "@vueform/multiselect/themes/default.css";

import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Info",

  components: {
    Step,
    Multiselect,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data.detail,
    }),
  },

  data() {
    return {
      detail: {
        type: "汽車全保",
        brand: "KIA",
        model: "Sorento",
        petrol: "",
        car_type: "",
        money: "",
        quality: "",
        year: "",
        discount: "",
        limit: "",
      },

      options: {
        petrol: [
          this.$t("info.options.type.petrol"),
          this.$t("info.options.type.oil"),
        ],

        years: (() => {
          let years = [];
          for (let i = 1990; i <= new Date().getFullYear(); i++) {
            years.push(i + "");
          }
          return years;
        })(),

        discount1: ["0%", "10%", "20%", "30%"],

        discount2: ["0%", "20%", "30%", "40%", "50%", "60%"],

        limit: [
          { value: "750000", label: "HK$ 750,000" },
          { value: "2000000", label: "HK$ 2,000,000" },
        ],
      },

      error: {
        type: false,
        brand: false,
        model: false,
        petrol: false,
        carType: false,
        money: false,
        quality: false,
        year: false,
        discount: false,
        limit: false,
      },
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache);
    this.detail.model = this.detail.model.replace("Sorento", "");
  },

  methods: {
    selectPetrol(value) {
      this.detail.discount = null;

      this.detail.car_type = value == "汽油" ? "私人車輛" : "商用車輛";

      this.detail.limit = value == "汽油" ? "2000000" : "750000";

      this.options.limit =
        value == "汽油"
          ? [{ value: "2000000", label: "HK$ 2,000,000" }]
          : [
              { value: "750000", label: "HK$ 750,000" },
              { value: "2000000", label: "HK$ 2,000,000" },
            ];

      this.check("petrol");
    },

    check(field) {
      let rules = {
        type: {
          model: this.detail.type,
          ref: "#s1",
          message: this.$t("info.verify.type"),
          fn: "require",
        },
        brand: {
          model: this.detail.brand,
          ref: "#s2",
          message: this.$t("info.verify.brand"),
          fn: "require",
        },
        model: {
          model: this.detail.model,
          ref: "#s3",
          message: this.$t("info.verify.model"),
          fn: "alphaDash",
        },
        petrol: {
          model: this.detail.petrol,
          ref: "#s4",
          message: this.$t("info.verify.petrol"),
          fn: "require",
        },
        carType: {
          model: this.detail.car_type,
          ref: "#s5",
          message: this.$t("info.verify.carType"),
          fn: "require",
        },
        money: {
          model: this.detail.money,
          ref: "#s6",
          message: this.$t("info.verify.money"),
          fn: "number",
        },
        quality: {
          model: this.detail.quality,
          ref: "#s7",
          message: this.$t("info.verify.quality"),
          fn: "number",
        },
        year: {
          model: this.detail.year,
          ref: "#s8",
          message: this.$t("info.verify.year"),
          fn: "number",
        },
        discount: {
          model: this.detail.discount,
          ref: "#s9",
          message: this.$t("info.verify.discount"),
          fn: "require",
        },
        limit: {
          model: this.detail.limit,
          ref: "#s10",
          message: this.$t("info.verify.limit"),
          fn: "require",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (!util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    next() {
      if (!this.check()) {
        return;
      }

      if (this.detail.year < 2005) {
        this.$router.push("error");
        return;
      }

      this.detail.model = "Sorento" + this.detail.model;

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setCache");
      this.$router.push("/step1");
    },
  },
};
</script>
<style scoped lang="less">
.model {
  position: relative;

  span {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    position: absolute;
    background: #eee;
  }

  input {
    padding-left: 80px !important;
  }
}
</style>