import { createRouter, createWebHistory } from "vue-router";

import Home from "./pages/Home";
import Info from "./pages/Info";
import Step1 from "./pages/Step1";
import Step2 from "./pages/Step2";
import Step3 from "./pages/Step3";
import Step4 from "./pages/Step4";
import Step5 from "./pages/Step5";
import Step6 from "./pages/Step6";
import Step7 from "./pages/Step7";
import Step8 from "./pages/Step8";
import Payment from "./pages/Payment";
import Detail from "./pages/Detail";
import Login from "./pages/Login";
import Success from "./pages/Success";
import Error from "./pages/Error";

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/info",
        component: Info
    },
    {
        path: "/step1",
        component: Step1
    },
    {
        path: "/step2",
        component: Step2
    },
    {
        path: "/step3",
        component: Step3
    },
    {
        path: "/step4",
        component: Step4
    },
    {
        path: "/step5",
        component: Step5
    },
    {
        path: "/step6",
        component: Step6
    },
    {
        path: "/step7",
        component: Step7
    },
    {
        path: "/step8",
        component: Step8
    },
    {
        path: "/payment",
        component: Payment
    },
    {
        path: "/detail",
        component: Detail
    },
    {
        path: "/error",
        component: Error
    },
    {
        path: "/payment/success/:id",
        component: Success
    },
    {
        path: "/login",
        component: Login
    }
];

const VueRouter = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default VueRouter;