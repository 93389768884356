<template>
  <div class="Modal" v-show="visible" v-on:click.self="close">
    <div class="modal-main" :class="className">
      <div class="modal-title">
        {{ title }}
        <button @click="close">×</button>
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
      <div class="modal-action">
        <button @click="close">{{ $t("close") }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",

  props: {
    visible: Boolean,
    title: String,
  },

  data() {
    return {
      className: "animate__animated animate__fadeInDown",
    };
  },

  methods: {
    close() {
      const self = this;

      self.className = "animate__animated animate__fadeOutUp";

      setTimeout(function () {
        self.$emit("update:visible", false);
        self.className = "animate__animated animate__fadeInDown";
      }, 500);
    },
  },
};
</script>
<style scoped lang="less">
.Modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .modal-main {
    margin: -270px 0 0 -400px;
    width: 800px;
    height: 540px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
  }

  .modal-title {
    padding: 15px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    button {
      font-family: inherit;
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
    }
  }

  .modal-content {
    padding: 15px;
    height: 420px;
    overflow: auto;
  }

  .modal-action {
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    button {
      float: right;
      color: #fff;
      background-color: #17a2b8;
      cursor: pointer;
      border: none;
      padding: 7px 10px;
      font-size: 16px;
      border-radius: 4px;
    }
  }

  @media (max-width: 880px) {
    .modal-main {
      width: auto;
      margin-left: 0;
      margin-right: 0;
      right: 10px;
      left: 10px;
    }
  }
}
</style>