const zhHK = {
    logging: "登入中...",
    loginError: "登入失敗，請重試",
    yes: "是",
    no: "否",
    ocring: "文字識別中",
    tips: "須知",
    close: "關閉",

    error: {
        file: "請選擇正確格式文件（JPG PNG PDF）",
        netword: "網絡錯誤，請重試上載"
    },

    header: {
        about: "關於我們",
        introduction: "公司簡介",
        partner: "合作夥伴",
        quote: "保險報價",
        claim: "裝修保險",
        domestic: "家傭保險",
        home: "家居保險",
        travel: "旅遊保險",
        owner: "置樂保",
        expressTravel: "特快旅遊保",
        helperTravel: "特快家傭保",
        bziPartner: "商業夥伴",
        konwledge: "保險知識",
        contactUs: "聯絡我們",
        login: "登入",
        logout: "登出",
        hello: "您好！"
    },

    home: {
        title: "單車意外保險",
        desc: "單車意外保險保險您騎單車當天因意外而涉及的醫療費用及損失。",
        referer: "由以下平臺轉介",
        submit: "立即投保",
    },

    step: {
        title: "汽車保險",
        step1: "填寫初步資料",
        step2: "確認報價",
        step3: "資料申報",
        step4: "車輛資料申報",
        step5: "填寫投保資料",
        step6: "填寫車主及駕駛者資料",
        step7: "確認投保資料",
        step8: "網上付款",
        step9: "完成投保",
        step10: "完成"
    },

    step1: {
        title: "初步報價",
    },

    step2: {
        money: "保費",
        moneySpec: "（包含保費徵費）",
        title1: "墊底費",
        spec1: "第一部分 - 車身損失或損毀",
        spec2: "第二部分 - 第三者法律責任",

        part1: {
            t1: "一般/車身損毀",
            t2: "盜竊損失",
            t3: "年輕司機",
            t4: "經驗不足司機",
            t5: "非指定司機",
            t6: "停泊損毀"
        },

        part2: {
            t1: "第三者財產損毀",
            t2: "年輕司機",
            t3: "經驗不足司機",
            t4: "非指定司機"
        }
    },

    step3: {
        title: "正式投保",
        title1: "資料申報",
        spec1: "所有司機（包括主要及其他記名司機）最近三年是否曾經因使用車輛被扣分或正接受調查或被檢控？",
        spec2: "所有司機（包括主要及其他記名司機）當中有否患有視力、聽覺障礙或任何身體殘障？",
        spec3: "所有司機（包括主要及其他記名司機）當中曾否於申請汽車保險時被拒投保、拒絕續保，或續保時被附加特別條款？",
        spec4: "所有司機（包括主要及其他記名司機）當中有否曾經再最近三年因使用車輛而發生意外、引致損失或要求賠償？"
    },

    step4: {
        title: "車輛資料申報",
        spec1: "車輛是否經過改裝或更改？",
        spec2: "車輛會否租賃予第三者作載客/載貨用途？",
        spec3: "車輛會否作為教授駕駛用途？",
        spec4: "車輛會否作為與銷售車輛有關之用途？",
        spec5: "車輛是否有安裝防盜裝置？（如有，請提供設備資料）",

        error: {
            theft: "請填寫您安裝的防盜裝置資料"
        }
    },

    step5: {
        upload: "點擊選擇文件",
        uploading: "上載中，請稍等...",
        history: "以往保險記錄"
    },

    step6: {
        title: "車輛資料",
        title1: "車主資料",
        title2: "駕駛者資料"
    },

    step8: {
        title: "確認投保資料"
    },

    contact: {
        title: "聯絡資料",
        name: "聯絡人姓名",
        email: "電郵地址",
        mobile: "手機號碼",
        desc: "*暫保單將透過電郵傳送",
        agree_1: "本人/我們確認已閱讀並理解貴公司的",
        agree_2: "條款和條件",
        agree_3: "聲明。",
        agree_4: "收集個人資料",
        agree_5: "本人同意接受其他產品的推廣優惠及資訊。",

        verify: {
            name: "請輸入聯絡人姓名",
            email: "請輸入正確的電郵地址",
            mobile: "請輸入正確的電話號碼"
        }
    },

    info: {
        title: "汽車保險",
        detail: "投保資料",
        noteDetail: "保單資料",
        type: "保險類別",
        brand: "品牌",
        model: "汽車型號",
        petrol: "汽油類型",
        carType: "汽車類別",
        money: "全保金額",
        quality: "載重噸數",
        year: "出廠年份",
        discount: "無索償折扣",
        limit: "第三者財物損毀限額",
        date: "起保日期",
        oldNote: "往年保單或續保通知書（如有無索償折扣，必須提交）",
        oldNoteNoSpec: "往年保單或續保通知書",
        financeCompany: "財務公司（如有）",
        oldCompany: "前保險公司名稱",
        notPayYears: "沒有索償的連續年數",
        carNum: "車牌號碼",
        noteNum: "保單號碼",
        isNew: "全新車輛",
        vehicleCert: "車輛登記證",
        chassisNum: "底盤號碼",
        engineNum: "引擎號碼",
        displacement: "車輛排量",
        bodyType: "車身類型",
        siteNum: "座位數量（不包括司機）",
        setterType: "投保人性質",
        paymentMethod: "選擇付款方式",
        signature: "簽名檔",
        search: "請輸入搜尋",

        edit: "修改資料",
        continue: "繼續投保",
        back: "返回",
        next: "下一步",
        state: "私隱政策",
        trem: "條款及細則",
        stateShow: "收集個人資料聲明",
        onlyEn: "只提供英文版本",

        owner: {
            name: "車主姓名",
            id: "香港身份證號碼",
            hkid: "香港身份證文件",
            job: "職業",
            isDriver: "車主是否駕駛者之一",
            trade: "行業",
            companyName: "公司名稱",
            bizCertNum: "商業登記證號碼",
            bizCert: "商業登記證",
            birthDay: "出生日期",
            sex: "性別",
            address: "通訊地址"
        },

        driver: {
            title: "駕駛者",
            name: "駕駛者姓名",
            noteNum: "駕駛執照號碼",
            note: "駕駛執照文件",
            years: "駕駛年資",
            relation: "與投保人關係",
            add: "駕駛者資料"
        },

        options: {
            type: {
                petrol: "汽油",
                oil: "柴油"
            },

            sex: {
                female: "女",
                male: "男"
            },

            setterType: {
                person: "個人",
                company: "公司"
            },

            relation: {
                t0: "本人",
                t1: "配偶",
                t2: "子女",
                t3: "親戚",
                t4: "朋友",
                t5: "僱員",
                t6: "僱主",
                t7: "同居伴侶"
            }
        },

        verify: {
            detail: "請回答所有問題",
            type: "請輸入保險類別",
            brand: "請輸入品牌",
            model: "請輸入汽車型號",
            petrol: "請選擇汽油類型",
            carType: "請選擇汽車類別",
            money: "請輸入全保金額",
            quality: "請輸入載重噸數",
            year: "請選擇出廠年份",
            discount: "請選擇無索償折扣",
            limit: "請選擇第三者財物損毀限額",
            oldNote: "請提交該項",
            date: "請選擇起保日期",
            isNew: "請選擇是否全新車輛",
            chassisNum: "請輸入底盤號碼",
            engineNum: "請輸入引擎號碼",
            displacement: "請輸入車輛排量",
            bodyType: "請輸入車身類型",
            carNum: "請輸入車牌號碼",
            siteNum: "請輸入座位數量",
            setterType: "請選擇投保人性質",
            name: "請輸入正確的車主姓名",
            id: "請輸入正確的身份證號碼",
            hkid: "請上載香港身份證文件",
            job: "請選擇職業",
            isDriver: "請選擇是否駕駛者",
            driver: "請上載駕駛執照文件",
            driverNum: "請輸入正確的駕駛執照號碼",
            ownerYears: "請填寫駕駛年資",
            trade: "請選擇行業",
            birthDay: "請選擇出生日期",
            sex: "請選擇性別",
            oldCompany: "請輸入前保險公司名稱",
            notPayYears: "請輸入沒有索償的連續年數",
            noteNum: "請輸入正確的保單號碼",
            vehicleCert: "請上載車輛登記證",
            bizCert: "請上載商業登記證",
            bizCertNum: "請填寫正確的商業登記證號碼",
            companyNum: "請填寫正確的公司名稱",
            relation: "請選擇與投保人關係",
            driverRequire: "請至少添加一位駕駛者資料",
            address: "請輸入正確的通訊地址",
            financeCompany: "請輸入正確的財務公司名",
            driverName: "請輸入正確的姓名",

            agree1: "請先確認已閱讀並理解條款和條件聲明。",
            agree2: "請先確認已閱讀並理解收集個人資料聲明。"
        }
    },

    detail: {
        t0: "本人確認以上資料正確無誤。",
        t1: "本人已閱讀並同意大新保險的個人資料收集聲明。",
        t2: "本人授權大新保險代表本人向過往保險公司索取有關以往之保險索償記錄。",
        t3: "本人同意大新保險使用我的個人資料作推廣資訊及直接促銷用途。",
        agree: "請確認條款及細則",
        clear: "清除",
        signEmpty: "請在簽名檔中簽名"
    }
};

export default zhHK;