<template>
  <Step :step="4" />

  <div class="page container">
    <div class="box">
      <HeaderStep :title="$t('step3.title')" :step="2"></HeaderStep>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("step4.title") }}</div>

        <div class="q-item">
          <div class="q-ask"><span>1.</span>{{ $t("step4.spec1") }}</div>
          <div class="q-answer">
            <span :class="{ active: q1 }" @click="q1 = true">
              {{ $t("yes") }}
            </span>
            <span :class="{ active: !q1 && q1 !== null }" @click="q1 = false">
              {{ $t("no") }}
            </span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>2.</span>{{ $t("step4.spec2") }}</div>
          <div class="q-answer">
            <span :class="{ active: q2 }" @click="q2 = true">
              {{ $t("yes") }}
            </span>
            <span :class="{ active: !q2 && q2 !== null }" @click="q2 = false">
              {{ $t("no") }}
            </span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>3.</span>{{ $t("step4.spec3") }}</div>
          <div class="q-answer">
            <span :class="{ active: q3 }" @click="q3 = true">
              {{ $t("yes") }}
            </span>
            <span :class="{ active: !q3 && q3 !== null }" @click="q3 = false">
              {{ $t("no") }}
            </span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>4.</span>{{ $t("step4.spec4") }}</div>
          <div class="q-answer">
            <span :class="{ active: q4 }" @click="q4 = true">
              {{ $t("yes") }}
            </span>
            <span :class="{ active: !q4 && q4 !== null }" @click="q4 = false">
              {{ $t("no") }}
            </span>
          </div>
        </div>

        <div class="q-item form-group">
          <div class="q-ask"><span>5.</span>{{ $t("step4.spec5") }}</div>
          <div class="q-answer">
            <span :class="{ active: q5 }" @click="q5 = true">
              {{ $t("yes") }}
            </span>

            <span :class="{ active: !q5 && q5 !== null }" @click="q5 = false">
              {{ $t("no") }}
            </span>

            <div class="form-item" v-show="q5">
              <input
                class="form-input"
                v-model="data.detail.theft"
                ref="theft"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">{{ $t("info.back") }}</button>
      <button class="btn btn-next" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";

export default {
  name: "Step4",

  components: {
    Step,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      data: {
        detail: {
          type: "汽車全保",
          brand: "KIA",
          model: "Sorento",
          petrol: "汽油",
          car_type: "私人車輛",
          money: "",
          quality: "",
          year: "2005",
          discount: "0%",
          limit: "750000",
          theft: "",
        },

        contact: {
          name: "",
          email: "",
          mobile: "",
          agree1: true,
          agree2: true,
          agree3: true,
        },
      },

      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
    };
  },

  methods: {
    init() {
      this.data = Object.assign(this.data, this.cache);

      this.q1 = this.cache.car_info_question.q1;
      this.q2 = this.cache.car_info_question.q2;
      this.q3 = this.cache.car_info_question.q3;
      this.q4 = this.cache.car_info_question.q4;
      this.q5 = this.cache.car_info_question.q5;
    },

    prev() {
      this.$router.push("/step3");
    },

    next() {
      if (
        this.q1 === null ||
        this.q2 === null ||
        this.q3 === null ||
        this.q4 === null ||
        this.q5 === null
      ) {
        alert(this.$t("info.verify.detail"));
        return;
      }

      if (this.q1 || this.q2 || this.q3 || this.q4) {
        this.$router.push("/error");
        return;
      }

      if (this.q5 && !this.data.detail.theft) {
        alert(this.$t("step4.error.theft"));
        this.$refs.theft.focus();
        return;
      }

      this.$store.commit("detail/setCarInfoQuestion", {
        q1: this.q1,
        q2: this.q2,
        q3: this.q3,
        q4: this.q4,
        q5: this.q5,
      });
      this.$store.commit("detail/setDetail", this.data.detail);
      this.$store.commit("detail/setCache");

      if (this.$route.query.confirm) {
        this.$router.push("/step8");
      } else {
        this.$router.push("/step5");
      }
    },
  },
};
</script>
<style scoped lang="less">
.form-input {
  margin-top: 10px;
}
</style>