<template>
  <div class="loading">
    <div class="loading-container">
      <img src="@/assets/images/loading.png" />
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",

  props: {
    message: String,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="less" scoped>
</style>