<template>
  <Step :step="8" />

  <div class="loading" v-if="loading">
    <div class="loading-container">
      <img src="@/assets/images/loading.png" />
    </div>
  </div>

  <div class="page container">
    <div class="box">
      <HeaderStep :title="$t('step.step8')" :step="3"></HeaderStep>
      <div class="info-payment">
        <div class="info-payment-container">
          <p class="info-payment-title">{{ $t("info.paymentMethod") }}</p>
          <div class="info-payment-item" @click="payment('alipay')">
            <div class="info-payment-logo">
              <img src="@/assets/images/alipayhk.png" />
            </div>
            AlipayHK
          </div>
          <div class="info-payment-item" @click="payment('paypal')">
            <div class="info-payment-logo">
              <img src="@/assets/images/paypal.png" />
            </div>
            Paypal
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";

export default {
  name: "Payment",

  components: {
    Step,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      loading: false,
      data: {},
      id: false,
      code: null,
    };
  },

  mounted() {
    this.data = Object.assign({}, this.cache);

    const code = window.localStorage.getItem("code");
    this.code = code;
  },

  methods: {
    prev() {
      this.$router.push("/step8");
    },

    async createOrder() {
      const result = await this.$fetch("order", {
        method: "POST",
        body: this.data,
      });

      if (!result.isOk) {
        alert(result.data.message);
        this.loading = false;
        return;
      }

      this.id = result.data.data.id;
    },

    async updateOrder() {
      const result = await this.$fetch(`order/error/${this.code}`, {
        method: "PUT",
        body: this.data,
      });

      if (!result.isOk) {
        alert(result.data.message);
        this.loading = false;
        return;
      }

      this.id = result.data.data.id;
    },

    async payment(type) {
      if (!this.code) {
        await this.createOrder();
      } else {
        await this.updateOrder();
      }

      const result = await this.$fetch(`payment/${type}/${this.id}`, {
        method: "POST",
      });

      const link = result.data.data.link;

      document.location.href = link;
    },
  },
};
</script>
<style scoped lang="less">
.info-payment {
  .info-payment-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      width: 30px;
      height: auto;
    }
  }
}

@media (max-width: 1140px) {
  .info-payment {
    .info-payment-container {
      width: 100%;
    }
  }
}
</style>