<template>
  <Step :step="6" />
  <div class="container page">
    <div class="box">
      <div class="form-group">
        <HeaderStep :title="$t('step3.title')" :step="2" id="s1"></HeaderStep>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.setterType }" id="s2">
            <label class="form-field">
              {{ $t("info.setterType") }}
              <span v-if="error.setterType">{{ error.setterType }}</span>
            </label>
            <div class="q-item">
              <div class="q-answer">
                <span
                  :class="{ active: detail.setter_type == 'person' }"
                  @click="detail.setter_type = 'person'"
                >
                  {{ $t("info.options.setterType.person") }}
                </span>
                <span
                  :class="{ active: detail.setter_type == 'company' }"
                  @click="detail.setter_type = 'company'"
                >
                  {{ $t("info.options.setterType.company") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group-title">{{ $t("step6.title1") }}</div>

        <template v-if="detail.setter_type == 'person'">
          <div class="form-line">
            <div class="form-item" :class="{ error: error.ownerName }" id="s3">
              <label class="form-field">
                {{ $t("info.owner.name") }}
                <span v-if="error.ownerName">
                  {{ error.ownerName }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.name"
                @blur="check('ownerName')"
              />
            </div>

            <div
              class="form-item"
              :class="{ error: error.ownerBirthDay }"
              id="s4"
            >
              <label class="form-field">
                {{ $t("info.owner.birthDay") }}
                <span v-if="error.ownerBirthDay">
                  {{ error.ownerBirthDay }}
                </span>
              </label>
              <flatPickr
                :config="Object.assign({ maxDate: 'today' }, config)"
                class="form-input"
                @on-change="check('ownerBirthDay')"
                v-model="detail.owner.birth_day"
              />
            </div>
          </div>

          <div class="form-line">
            <div class="form-item" :class="{ error: error.ownerSex }" id="s5">
              <label class="form-field">
                {{ $t("info.owner.sex") }}
                <span v-if="error.ownerSex">{{ error.ownerSex }}</span>
              </label>
              <Multiselect
                class="form-input"
                v-model="detail.owner.sex"
                :options="options.sex"
                @select="check('ownerSex')"
                :allowEmpty="false"
              />
            </div>

            <div class="form-item" :class="{ error: error.ownerId }" id="s6">
              <label class="form-field">
                {{ $t("info.owner.id") }}
                <span v-if="error.ownerId">{{ error.ownerId }}</span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.id"
                @blur="check('ownerId')"
              />
            </div>
          </div>

          <div class="form-line">
            <div class="form-item" :class="{ error: error.ownerHkid }" id="s7">
              <label class="form-field">
                {{ $t("info.owner.hkid") }}
                <span v-if="error.ownerHkid">{{ error.ownerHkid }}</span>
              </label>

              <div
                class="form-input"
                v-if="!confirm"
                @click="
                  tips = true;
                  confirm = true;
                "
              >
                {{
                  !detail.owner.hkid_filename
                    ? $t("step5.upload")
                    : detail.owner.hkid_filename
                }}
              </div>

              <file-upload
                input-id="ownerHkidInput"
                :post-action="api"
                ref="hkidUpload"
                @input-file="inputOwnerHkid"
                @input-filter="filterOwnerHkid"
                v-show="confirm"
              >
                <div class="form-input" v-if="!detail.owner.hkid_filename">
                  {{ $t("step5.upload") }}
                </div>
                <div class="form-input" v-else>
                  {{ detail.owner.hkid_filename }}
                </div>
              </file-upload>
            </div>

            <div class="form-item" :class="{ error: error.ownerJob }" id="s8">
              <label class="form-field">
                {{ $t("info.owner.job") }}
                <span v-if="error.ownerJob">{{ error.ownerJob }}</span>
              </label>
              <Multiselect
                class="form-input search"
                v-model="detail.owner.job"
                :options="options.jobs"
                @select="check('ownerJob')"
                :allowEmpty="false"
                track-by="label"
                :searchable="true"
                :placeholder="$t('info.search')"
              />
            </div>
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{ error: error.ownerIsDriver }"
              id="s9"
            >
              <label class="form-field">
                {{ $t("info.owner.isDriver") }}
                <span v-if="error.ownerIsDriver">
                  {{ error.ownerIsDriver }}
                </span>
              </label>
              <Multiselect
                class="form-input"
                v-model="detail.owner.is_driver"
                :options="options.isDriver"
                :allowEmpty="false"
                @select="selectIsDriver"
              />
            </div>

            <div
              class="form-item"
              :class="{ error: error.ownerDriverNum }"
              id="s10"
              v-show="detail.owner.is_driver"
            >
              <label class="form-field">
                {{ $t("info.driver.noteNum") }}
                <span v-if="error.ownerDriverNum">
                  {{ error.ownerDriverNum }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.driver_num"
                @blur="check('ownerDriverNum')"
              />
            </div>
          </div>

          <div class="form-line" v-show="detail.owner.is_driver">
            <div
              class="form-item"
              :class="{ error: error.ownerYears }"
              id="s12"
            >
              <label class="form-field">
                {{ $t("info.driver.years") }}
                <span v-if="error.ownerYears">{{ error.ownerYears }}</span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.years"
                @blur="check('ownerYears')"
                type="number"
              />
            </div>

            <div
              class="form-item"
              :class="{ error: error.ownerDriver }"
              id="s11"
            >
              <label class="form-field">
                {{ $t("info.driver.note") }}
                <span v-if="error.ownerDriver">{{ error.ownerDriver }}</span>
              </label>

              <div
                class="form-input"
                v-if="!confirm"
                @click="
                  tips = true;
                  confirm = true;
                "
              >
                {{
                  !detail.owner.driver_filename
                    ? $t("step5.upload")
                    : detail.owner.driver_filename
                }}
              </div>

              <file-upload
                input-id="ownerDriverInput"
                :post-action="api"
                ref="ownerDirverUpload"
                @input-file="inputOwnerDriver"
                @input-filter="filterOwnerDriver"
                v-show="confirm"
              >
                <div class="form-input" v-if="!detail.owner.driver_filename">
                  {{ $t("step5.upload") }}
                </div>
                <div class="form-input" v-else>
                  {{ detail.owner.driver_filename }}
                </div>
              </file-upload>
            </div>
          </div>
        </template>

        <template v-if="detail.setter_type == 'company'">
          <div class="form-line">
            <div
              class="form-item"
              :class="{ error: error.ownerCompanyName }"
              id="s13"
            >
              <label class="form-field">
                {{ $t("info.owner.companyName") }}
                <span v-if="error.ownerCompanyName">
                  {{ error.ownerCompanyName }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.company_name"
                @blur="check('ownerCompanyName')"
              />
            </div>

            <div
              class="form-item"
              :class="{ error: error.ownerTrade }"
              id="s14"
            >
              <label class="form-field">
                {{ $t("info.owner.trade") }}
                <span v-if="error.ownerTrade">{{ error.ownerTrade }}</span>
              </label>
              <Multiselect
                class="form-input search"
                v-model="detail.owner.trade"
                :options="options.jobs"
                @select="check('ownerTrade')"
                :allowEmpty="false"
                :searchable="true"
                track-by="label"
                :placeholder="$t('info.search')"
              />
            </div>
          </div>
          <div class="form-line">
            <div
              class="form-item"
              :class="{ error: error.ownerBizCertNum }"
              id="s15"
            >
              <label class="form-field">
                {{ $t("info.owner.bizCertNum") }}
                <span v-if="error.ownerBizCertNum">
                  {{ error.ownerBizCertNum }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="detail.owner.biz_cert_num"
                @blur="check('ownerBizCertNum')"
              />
            </div>

            <div
              class="form-item"
              :class="{ error: error.ownerBizCert }"
              id="s16"
            >
              <label class="form-field">
                {{ $t("info.owner.bizCert") }}
                <span v-if="error.ownerBizCert">
                  {{ error.ownerBizCert }}
                </span>
              </label>
              <file-upload
                input-id="ownerBizCertInput"
                :post-action="api"
                ref="ownerBizCertUpload"
                @input-file="inputOwnerBizCert"
                @input-filter="filterOwnerBizCert"
              >
                <div class="form-input" v-if="!detail.owner.biz_cert_filename">
                  {{ $t("step5.upload") }}
                </div>
                <div class="form-input" v-else>
                  {{ detail.owner.biz_cert_filename }}
                </div>
              </file-upload>
            </div>
          </div>
        </template>

        <div
          class="form-item form-line"
          :class="{ error: error.ownerAddress }"
          id="s17"
        >
          <label class="form-field">
            {{ $t("info.owner.address") }}
            <span v-if="error.ownerAddress">
              {{ error.ownerAddress }}
            </span>
          </label>
          <input
            class="form-input"
            v-model="detail.owner.address"
            @blur="check('ownerAddress')"
          />
        </div>

        <div class="form-group-title">{{ $t("step6.title2") }}</div>

        <template v-for="(drive, index) in drivers" :key="index">
          <div class="driver-title" :id="'ss1_' + index">
            <i
              class="remove"
              v-if="drivers.length > 1 || detail.owner.is_driver"
              @click="removeDriver(index)"
            ></i>
            {{ $t("info.driver.title") }}{{ index + 1 }}
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].name,
              }"
              :id="'ss2_' + index"
            >
              <label class="form-field">
                {{ $t("info.driver.name") }}
                <span v-if="error.drivers[index] && error.drivers[index].name">
                  {{ error.drivers[index].name }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="drivers[index].name"
                @blur="checkDriver(index, 'name')"
              />
            </div>

            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].birthDay,
              }"
              :id="'ss3_' + index"
            >
              <label class="form-field">
                {{ $t("info.owner.birthDay") }}
                <span
                  v-if="error.drivers[index] && error.drivers[index].birthDay"
                >
                  {{ error.drivers[index].birthDay }}
                </span>
              </label>
              <flatPickr
                :config="Object.assign({ maxDate: 'today' }, config)"
                class="form-input"
                v-model="drivers[index].birth_day"
              />
            </div>
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].sex,
              }"
              :id="'ss4_' + index"
            >
              <label class="form-field">
                {{ $t("info.owner.sex") }}
                <span v-if="error.drivers[index] && error.drivers[index].sex">
                  {{ error.drivers[index].sex }}
                </span>
              </label>
              <Multiselect
                class="form-input"
                v-model="drivers[index].sex"
                :options="options.sex"
                :allowEmpty="false"
              />
            </div>

            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].job,
              }"
              :id="'ss5_' + index"
            >
              <label class="form-field">
                {{ $t("info.owner.job") }}
                <span v-if="error.drivers[index] && error.drivers[index].job">
                  {{ error.drivers[index].job }}
                </span>
              </label>

              <Multiselect
                class="form-input search"
                v-model="drivers[index].job"
                :options="options.jobs"
                :allowEmpty="false"
                track-by="label"
                :searchable="true"
                :placeholder="$t('info.search')"
              />
            </div>
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].id,
              }"
              :id="'ss6_' + index"
            >
              <label class="form-field">
                {{ $t("info.owner.id") }}
                <span v-if="error.drivers[index] && error.drivers[index].id">
                  {{ error.drivers[index].id }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="drivers[index].id"
                @blur="checkDriver(index, 'id')"
              />
            </div>

            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].hkid,
              }"
              :id="'ss7_' + index"
            >
              <label class="form-field">
                {{ $t("info.owner.hkid") }}
                <span v-if="error.drivers[index] && error.drivers[index].hkid">
                  {{ error.drivers[index].hkid }}
                </span>
              </label>
              <file-upload
                :input-id="'driversHkidInput_' + index"
                :post-action="api"
                :ref="'driversHkidUpload_' + index"
                @input="selectDriversHkid(index)"
                @input-file="inputDriversHkid"
                @input-filter="filterDriversHkid"
              >
                <div class="form-input" v-if="!drivers[index].hkid_filename">
                  {{ $t("step5.upload") }}
                </div>
                <div class="form-input" v-else>
                  {{ drivers[index].hkid_filename }}
                </div>
              </file-upload>
            </div>
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].noteNum,
              }"
              :id="'ss8_' + index"
            >
              <label class="form-field">
                {{ $t("info.driver.noteNum") }}
                <span
                  v-if="error.drivers[index] && error.drivers[index].noteNum"
                >
                  {{ error.drivers[index].noteNum }}
                </span>
              </label>
              <input
                class="form-input"
                v-model="drivers[index].note_num"
                @blur="checkDriver(index, 'noteNum')"
              />
            </div>

            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].note,
              }"
              :id="'ss9_' + index"
            >
              <label class="form-field">
                {{ $t("info.driver.note") }}
                <span v-if="error.drivers[index] && error.drivers[index].note">
                  {{ error.drivers[index].note }}
                </span>
              </label>
              <file-upload
                :input-id="'driversNoteInput_' + index"
                :post-action="api"
                :ref="'driversNoteUpload_' + index"
                @input="selectDriversNote(index)"
                @input-file="inputDriversNote"
                @input-filter="filterDriversNote"
              >
                <div class="form-input" v-if="!drivers[index].note_filename">
                  {{ $t("step5.upload") }}
                </div>
                <div class="form-input" v-else>
                  {{ drivers[index].note_filename }}
                </div>
              </file-upload>
            </div>
          </div>

          <div class="form-line">
            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].years,
              }"
              :id="'ss10_' + index"
            >
              <label class="form-field">
                {{ $t("info.driver.years") }}
                <span v-if="error.drivers[index] && error.drivers[index].years">
                  {{ error.drivers[index].years }}
                </span>
              </label>
              <input
                class="form-input"
                type="number"
                v-model="drivers[index].years"
                @blur="checkDriver(index, 'years')"
              />
            </div>

            <div
              class="form-item"
              :class="{
                error: error.drivers[index] && error.drivers[index].relation,
              }"
            >
              <label class="form-field">
                {{ $t("info.driver.relation") }}
                <span
                  v-if="error.drivers[index] && error.drivers[index].relation"
                >
                  {{ error.drivers[index].relation }}
                </span>
              </label>
              <Multiselect
                class="form-input"
                v-model="drivers[index].relation"
                :options="options.relations"
                :allowEmpty="false"
              />
            </div>
          </div>
        </template>

        <div class="add" @click="addDriver">
          <i></i> {{ $t("info.driver.add") }}
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">
        {{ $t("info.back") }}
      </button>
      <button class="btn btn-next" @click="next">
        {{ $t("info.next") }}
      </button>
    </div>
  </div>

  <Loading :message="$t('ocring')" v-if="ocrLoading" />

  <Modal v-model:visible="tips" :title="$t('tips')">
    <p>為達到最佳文字辨識效果，請注意以下幾點：</p>
    <ul>
      <li>請維持文件完整性，不能切角或切邊／反光／模糊不清／遮住個人資料</li>
      <li>拍照之前要先對焦-手機：點一下螢幕、相機：輕按快門</li>
      <li>將拍攝物直立，可避免因光源所造成的反白</li>
      <li>拍照照片最低解析度為 1280x1024，掃描請設定 300 DPI 以上</li>
    </ul>
  </Modal>
</template>
<script>
import Step from "../../components/Step";
import Loading from "../../components/Loading";
import HeaderStep from "../../components/HeaderStep";
import Multiselect from "@vueform/multiselect";
import FileUpload from "vue-upload-component";
import Modal from "../../components/Modal";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw";
import { Mandarin } from "flatpickr/dist/l10n/zh";

import util from "../../helper/util";
import { mapState } from "vuex";

import jobs from "../../data/jobs";
import config from "../../config";

import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Step7",

  components: {
    Step,
    HeaderStep,
    Multiselect,
    FileUpload,
    flatPickr,
    Loading,
    Modal,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      api: config.api + "file",

      ocrLoading: false,

      config: {
        disable: ["1900-01-01"],
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        locale: localStorage.lang == "zhHK" ? MandarinTraditional : Mandarin,
      },

      detail: {
        setter_type: "person",

        owner: {
          birth_day: "",
          sex: "",
          id: "",
          hkid: "",
          job: "",
          address: "",
          is_driver: 1,
          driver_num: "",
          driver: "",
          driver_name: "",
          years: "",
          company_name: "",
          biz_cert_num: "",
          biz_cert: "",
          biz_cert_filename: "",
        },
      },

      drivers: [],

      error: {
        setterType: false,

        ownerId: false,
        ownerBirthDay: false,
        ownerSex: false,
        ownerHkid: false,
        ownerJob: false,
        ownerDriver: false,
        ownerYears: false,
        ownerTrade: false,
        ownerCompanyName: false,
        ownerBizCertNum: false,
        ownerAddress: false,

        drivers: [],
      },

      options: {
        jobs: Object.values(jobs),

        sex: [
          {
            label: this.$t("info.options.sex.female"),
            value: "F",
          },
          {
            label: this.$t("info.options.sex.male"),
            value: "M",
          },
        ],

        isDriver: [
          {
            label: this.$t("yes"),
            value: 1,
          },
          {
            label: this.$t("no"),
            value: 0,
          },
        ],

        relations: [
          {
            label: this.$t("info.options.relation.t1"),
            value: "配偶",
          },
          {
            label: this.$t("info.options.relation.t2"),
            value: "子女",
          },
          {
            label: this.$t("info.options.relation.t3"),
            value: "親戚",
          },
          {
            label: this.$t("info.options.relation.t4"),
            value: "朋友",
          },
          {
            label: this.$t("info.options.relation.t5"),
            value: "僱員",
          },
          {
            label: this.$t("info.options.relation.t6"),
            value: "僱主",
          },
          {
            label: this.$t("info.options.relation.t7"),
            value: "同居伴侶",
          },
        ],
      },

      driversHkidIndex: 0,
      driversNoteIndex: 0,

      tips: false,
      confirm: false,
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache.detail);
    this.drivers = Object.assign(this.drivers, this.cache.drivers);
  },

  methods: {
    addDriver() {
      this.drivers.push({
        name: "",
        id: "",
        hkid: "",
        hkid_file_name: "",
        job: "",
        note: "",
        note_filename: "",
        years: "",
        relation: "",
        sex: "",
        birth_day: "",
      });
    },

    removeDriver(index) {
      if (this.drivers.length <= 0) {
        return;
      }

      this.drivers.splice(index, 1);
    },

    prev() {
      this.$router.push("/step6");
    },

    next() {
      if (!this.check()) {
        return;
      }

      if (!this.checkDriver()) {
        return;
      }

      if (this.detail.setter_type == "company" && this.drivers.length < 1) {
        alert(this.$t("info.verify.driverRequire"));
        return;
      }

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setDrivers", this.drivers);
      this.$store.commit("detail/setCache");

      let job = Number(
        this.detail.setter_type == "person"
          ? this.detail.owner.job
          : this.detail.owner.trade
      );

      if (
        jobs[job].referral ||
        (this.detail.owner.setter_type == "person" &&
          this.detail.owner.is_driver &&
          this.detail.owner.years < 2)
      ) {
        this.$router.push("/error");
        return;
      }

      for (let i = 0, length = this.drivers.length; i < length; i++) {
        if (
          jobs[this.drivers[i].job].referral ||
          this.drivers[i].years < 2 ||
          util.getAge(this.drivers[i].birth_day) < 25
        ) {
          this.$router.push("/error");
          return;
        }
      }

      if (this.detail.setter_type == "person" && !this.detail.owner.is_driver) {
        this.$router.push("/error");
        return;
      }

      if (
        this.detail.setter_type == "person" &&
        util.getAge(this.detail.owner.birth_day) < 25
      ) {
        this.$router.push("/error");
        return;
      }

      this.$router.push("/step8");
    },

    check(field) {
      const self = this;

      let rules = {
        setterType: {
          model: this.detail.setter_type,
          ref: "#s1",
          message: this.$t("info.verify.setterType"),
          fn: "require",
        },
        ownerName: {
          model: this.detail.owner.name,
          ref: "#s2",
          message: this.$t("info.verify.name"),
          before: () => self.detail.setter_type == "person",
          fn: "alphaDash",
        },
        ownerSex: {
          model: this.detail.owner.sex,
          ref: "#s3",
          message: this.$t("info.verify.sex"),
          before: () => self.detail.setter_type == "person",
          fn: "require",
        },
        ownerBirthDay: {
          model: this.detail.owner.birth_day,
          ref: "#s4",
          message: this.$t("info.verify.birthDay"),
          before: () => self.detail.setter_type == "person",
          fn: "require",
        },
        ownerId: {
          model: this.detail.owner.id,
          ref: "#s5",
          message: this.$t("info.verify.id"),
          before: () => self.detail.setter_type == "person",
          fn: "checkId",
        },
        ownerHkid: {
          model: this.detail.owner.hkid,
          ref: "#s6",
          message: this.$t("info.verify.hkid"),
          before: () => self.detail.setter_type == "person",
          fn: "require",
        },
        ownerJob: {
          model: this.detail.owner.job,
          ref: "#s7",
          message: this.$t("info.verify.job"),
          before: () => self.detail.setter_type == "person",
          fn: "require",
        },
        ownerIsDriver: {
          model: this.detail.owner.is_driver,
          ref: "#s8",
          message: this.$t("info.verify.isDriver"),
          fn: "require",
        },
        ownerDriverNum: {
          model: this.detail.owner.driver_num,
          ref: "#s9",
          message: this.$t("info.verify.driverNum"),
          before: () =>
            self.detail.setter_type == "person" && self.detail.owner.is_driver,
          fn: "alphaDash",
        },
        ownerDriver: {
          model: this.detail.owner.driver,
          ref: "#s10",
          message: this.$t("info.verify.driver"),
          before: () =>
            self.detail.setter_type == "person" && self.detail.owner.is_driver,
          fn: "require",
        },
        ownerYears: {
          model: this.detail.owner.years,
          ref: "#s11",
          message: this.$t("info.verify.ownerYears"),
          before: () =>
            self.detail.setter_type == "person" && self.detail.is_driver,
          fn: "number",
        },
        ownerBizCert: {
          model: this.detail.owner.biz_cert,
          ref: "#s1",
          message: this.$t("info.verify.bizCert"),
          before: () => self.detail.setter_type == "company",
          fn: "require",
        },
        ownerBizCertNum: {
          model: this.detail.owner.biz_cert_num,
          ref: "#s1",
          message: this.$t("info.verify.bizCertNum"),
          before: () => self.detail.setter_type == "company",
          fn: "alphaDash",
        },
        ownerTrade: {
          model: this.detail.owner.trade,
          ref: "#s2",
          message: this.$t("info.verify.trade"),
          before: () => self.detail.setter_type == "company",
          fn: "require",
        },
        ownerCompanyName: {
          model: this.detail.owner.company_name,
          ref: "#s2",
          message: this.$t("info.verify.companyNum"),
          before: () => self.detail.setter_type == "company",
          fn: "alphaDash",
        },
        ownerAddress: {
          model: this.detail.owner.address,
          ref: "#s2",
          message: this.$t("info.verify.address"),
          fn: "alphaDash",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (rule.before && !rule.before()) {
          this.error[key] = false;
          continue;
        }

        if (rule.ref && !util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    checkDriver(index, field) {
      let drivers;

      if (index !== undefined) {
        drivers = [this.drivers[index]];
      } else {
        drivers = this.drivers;
      }

      let rules = {
        name: {
          model: "name",
          ref: "#ss1_",
          message: this.$t("info.verify.name"),
          fn: "alphaDash",
        },
        birthDay: {
          model: "birth_day",
          ref: "#ss2_",
          message: this.$t("info.verify.birthDay"),
          fn: "require",
        },
        sex: {
          model: "sex",
          ref: "#ss3_",
          message: this.$t("info.verify.sex"),
          fn: "require",
        },
        job: {
          model: "job",
          ref: "#ss4_",
          message: this.$t("info.verify.job"),
          fn: "require",
        },
        id: {
          model: "id",
          ref: "#ss5_",
          message: this.$t("info.verify.id"),
          fn: "checkId",
        },
        hkid: {
          model: "hkid",
          ref: "#ss6_",
          message: this.$t("info.verify.hkid"),
          fn: "require",
        },
        noteNum: {
          model: "note_num",
          ref: "#ss7_",
          message: this.$t("info.verify.driverNum"),
          fn: "alphaDash",
        },
        note: {
          model: "note",
          ref: "#ss8_",
          message: this.$t("info.verify.driver"),
          fn: "require",
        },
        years: {
          model: "years",
          ref: "#ss9_",
          message: this.$t("info.verify.ownerYears"),
          fn: "number",
        },
        relation: {
          model: "relation",
          ref: "#ss10_",
          message: this.$t("info.verify.relation"),
          fn: "require",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let i = 0, length = drivers.length; i < length; i++) {
        let driver = drivers[i];

        if (index !== undefined && !this.error.drivers[index]) {
          this.error.drivers[index] = {};
        } else if (index === undefined && !this.error.drivers[i]) {
          this.error.drivers[i] = {};
        }

        for (let key in rules) {
          let rule = rules[key];

          if (rules.before && !rule.before()) {
            if (index !== undefined) {
              this.error.drivers[index][key] = false;
            } else {
              this.error.drivers[i][key] = false;
            }
          }

          if (rule.ref && !util[rule.fn](driver[rule.model])) {
            if (index !== undefined) {
              this.error.drivers[index][key] = rule.message;
            } else {
              this.error.drivers[i][key] = rule.message;
            }

            if (!field && result) {
              if (index !== undefined) {
                scrollTo(rule.ref + index);
              } else {
                scrollTo(rule.ref + i);
              }
            }

            result = false;
          } else {
            if (index !== undefined) {
              this.error.drivers[index][key] = false;
            } else {
              this.error.drivers[i][key] = false;
            }
          }
        }
      }

      return result;
    },

    inputOwnerHkid(newFile, oldFile) {
      this.inputFile(newFile, oldFile, "detail.owner.hkid", "hkidUpload", "id");
    },

    filterOwnerHkid(newFile, oldFile, prevent) {
      this.inputFilter(newFile, oldFile, prevent, "hkidUpload");
    },

    inputOwnerDriver(newFile, oldFile) {
      this.inputFile(
        newFile,
        oldFile,
        "detail.owner.driver",
        "ownerDirverUpload",
        "driver"
      );
    },

    filterOwnerDriver(newFile, oldFile, prevent) {
      this.inputFilter(newFile, oldFile, prevent, "ownerDirverUpload");
    },

    inputOwnerBizCert(newFile, oldFile) {
      this.inputFile(
        newFile,
        oldFile,
        "detail.owner.biz_cert",
        "ownerBizCertUpload"
      );
    },

    filterOwnerBizCert(newFile, oldFile, prevent) {
      this.inputFilter(newFile, oldFile, prevent, "ownerBizCertUpload");
    },

    inputDriversHkid(newFile, oldFile) {
      this.inputFile(
        newFile,
        oldFile,
        "drivers." + this.driversHkidIndex + ".hkid",
        "driversHkidUpload_" + this.driversHkidIndex,
        "id"
      );
    },

    filterDriversHkid(newFile, oldFile, prevent) {
      this.inputFilter(
        newFile,
        oldFile,
        prevent,
        "driversHkidUpload_" + this.driversHkidIndex
      );
    },

    selectDriversHkid(index) {
      this.driversHkidIndex = index;
    },

    inputDriversNote(newFile, oldFile) {
      this.inputFile(
        newFile,
        oldFile,
        "drivers." + this.driversNoteIndex + ".note",
        "driversNoteUpload_" + this.driversNoteIndex,
        "driver"
      );
    },

    filterDriversNote(newFile, oldFile, prevent) {
      this.inputFilter(
        newFile,
        oldFile,
        prevent,
        "driversNoteUpload_" + this.driversNoteIndex
      );
    },

    selectDriversNote(index) {
      this.driversNoteIndex = index;
    },

    async inputFile(newFile, oldFile, modelKey, ref, ocr) {
      const model = modelKey.split(".");

      // Upload progress
      if ((newFile && newFile.progress) || (oldFile && oldFile.progress)) {
        if (model.length == 2) {
          this[model[0]][model[1] + "_filename"] = this.$t("step5.uploading");
        } else if (model.length == 3) {
          this[model[0]][model[1]][model[2] + "_filename"] =
            this.$t("step5.uploading");
        }
      } else if (newFile && !newFile.success) {
        if (model.length == 2) {
          this[model[0]][model[1]] = "";
          this[model[0]][model[1] + "_filename"] = this.$t("error.netword");
        } else if (model.length == 3) {
          this[model[0]][model[1]][model[2]] = "";
          this[model[0]][model[1]][model[2] + "_filename"] =
            this.$t("error.netword");
        }

        return false;
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs[ref].active) {
          this.$refs[ref].active = true;
        }
      }

      if (!newFile) {
        return false;
      }

      const data = newFile.response.data;

      if (!data) {
        return;
      }

      if (model.length == 2) {
        this[model[0]][model[1]] = data.save_name;
        this[model[0]][model[1] + "_filename"] = data.origin_name;
      } else if (model.length == 3) {
        this[model[0]][model[1]][model[2]] = data.save_name;
        this[model[0]][model[1]][model[2] + "_filename"] = data.origin_name;
      }

      if (ocr) {
        this.ocrLoading = true;

        const ocrResult = await this.$fetch(
          "ocr/" + ocr + "?file=" + data.save_name
        );

        this.ocrLoading = false;

        this.ocrInput(ocrResult.data.data, model, ocr);
      }

      this.$refs[ref].active = false;
    },

    inputFilter(newFile, oldFile, prevent, ref) {
      if (newFile && !oldFile) {
        // 过滤不是图片后缀的文件
        if (!/\.(jpeg|jpe|jpg|png|pdf)$/i.test(newFile.name)) {
          alert(this.$t("error.file"));
          return prevent();
        }
      }

      this.$refs[ref].active = true;
    },

    ocrInput(result, model, ocr) {
      if (ocr == "id") {
        const ocrResult = {
          date: result.date.replace(/-/g, "/"),
          sex: result.gender.indexOf("F") ? "F" : "M",
          id: result.id.slice(0, 7),
          name: result.name,
        };

        if (model[0] == "detail" && model[1] == "owner") {
          this.detail.owner = Object.assign(this.detail.owner, {
            birth_day: ocrResult.date,
            sex: ocrResult.sex,
            id: ocrResult.id,
            name: ocrResult.name,
          });
        }

        if (model[0] == "drivers") {
          this.drivers[model[1]] = Object.assign(this.drivers[model[1]], {
            name: ocrResult.name,
            id: ocrResult.id,
            sex: ocrResult.sex,
            birth_day: ocrResult.date,
          });
        }
      }

      if (ocr == "driver") {
        if (model[0] == "detail" && model[1] == "owner") {
          this.detail.owner = Object.assign(this.detail.owner, {
            driver_num: result.DriverLicense,
          });
        }

        if (model[0] == "drivers") {
          this.drivers[model[1]] = Object.assign(this.drivers[model[1]], {
            note_num: result.DriverLicense,
          });
        }
      }
    },

    selectIsDriver(isDriver) {
      if (isDriver && this.drivers.length == 1) {
        let isEmpty =
          Object.values(this.drivers[0]).filter((v) => {
            return v != "";
          }).length == 0;

        if (isEmpty) {
          this.drivers = [];
        }
      }

      if (!isDriver && this.drivers.length <= 0) {
        this.drivers = [
          {
            name: "",
            hkid: "",
            hkid_file_name: "",
            job: "",
            note: "",
            note_filename: "",
            years: "",
            relation: "",
            sex: "",
            birth_day: "",
          },
        ];
      }
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.multiselect) {
  &.search {
    padding-left: 45px !important;
    position: relative;

    &::before {
      content: "";
      width: 25px;
      height: 25px;
      display: block;
      position: absolute;
      z-index: 90;
      top: 5px;
      left: 10px;
      background: url("../../assets/images/search.png") no-repeat center;
      background-size: 25px;
    }

    .multiselect-search {
      padding-left: 45px;
      background: #eee;
    }

    .multiselect-placeholder,
    .multiselect-single-label {
      left: 35px;
    }
  }
}

.driver-title {
  font-size: 16px;
  margin: 0 0 16px 0;
}

.add {
  line-height: 16px;
  cursor: pointer;

  * {
    vertical-align: bottom;
  }

  i {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background: url("../../assets/images/add.png") center no-repeat;
    background-size: 16px;
  }
}

.remove {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  background: url("../../assets/images/reduce.png") center no-repeat;
  background-size: 16px;
  cursor: pointer;
}

.q-answer {
  span::before {
    left: 35px !important;
  }
}
</style>