const zhCN = {
    logging: "登入中...",
    loginError: "登入失败，请重试",
    yes: "是",
    no: "否",
    tips: "须知",
    close: "关闭",

    error: {
        file: "请选择正确格式文件（JPG PNG PDF）"
    },

    header: {
        about: "关于我们",
        introduction: "公司简介",
        partner: "合作伙伴",
        quote: "保险报价",
        claim: "装修保险",
        domestic: "家佣保险",
        home: "家居保险",
        travel: "旅游保险",
        owner: "置乐保",
        expressTravel: "特快旅游保",
        helperTravel: "特快家佣保",
        bziPartner: "商业伙伴",
        konwledge: "保险知识",
        contactUs: "联络我们",
        login: "登入",
        logout: "登出",
        hello: "您好！"
    },

    home: {
        title: "单车意外保险",
        desc: "单车意外保险保险您骑单车当天因意外而涉及的医疗费用及损失。",
        referer: "由以下平台转介",
        submit: "立即投保",
    },

    step: {
        title: "汽车保险",
        step1: "填写初步资料",
        step2: "确认报价",
        step3: "资料申报",
        step4: "车辆资料申报",
        step5: "填写投保资料",
        step6: "填写车主及驾驶者资料",
        step7: "确认投保资料",
        step8: "网上付款",
        step9: "完成投保",
        step10: "完成"
    },

    step1: {
        title: "初步报价",
    },

    step2: {
        money: "保费",
        moneySpec: "（包含保费征费）",
        title1: "垫底费",
        spec1: "第一部分 - 车身损失或损毁",
        spec2: "第二部分 - 第三者法律责任",

        part1: {
            t1: "一般/车身损毁",
            t2: "盗窃损失",
            t3: "年轻司机",
            t4: "经验不足司机",
            t5: "非指定司机",
            t6: "停泊损毁"
        },

        part2: {
            t1: "第三者财产损毁",
            t2: "年轻司机",
            t3: "经验不足司机",
            t4: "非指定司机"
        }
    },

    step3: {
        title: "正式投保",
        title1: "资料申报",
        spec1: "所有司机（包括主要及其他记名司机）最近三年是否曾经因使用车辆被扣分或正接受调查或被检控？",
        spec2: "所有司机（包括主要及其他记名司机）当中有否患有视力、听觉障碍或任何身体残障？",
        spec3: "所有司机（包括主要及其他记名司机）当中曾否于申请汽车保险时被拒投保、拒绝续保，或续保时被附加特别条款？",
        spec4: "所有司机（包括主要及其他记名司机）当中有否曾经再最近三年因使用车辆而发生意外、引致损失或要求赔偿？"
    },

    step4: {
        title: "车辆资料申报",
        spec1: "车辆是否经过改装或更改？（如是，请说明所有改装细节）",
        spec2: "车辆会否租赁予第三者作载客/载货用途？",
        spec3: "车辆会否作为教授驾驶用途？",
        spec4: "车辆会否作为与销售车辆有关之用途？",
        spec5: "车辆是否有安装防盗装置？（如有，请提供设备资料）",

        error: {
            theft: "请填写您安装的防盗装置资料"
        }
    },

    step5: {
        upload: "点击选择文件",
        uploading: "上载中，请稍等...",
        history: "以往保险记录"
    },

    step6: {
        title: "车辆资料",
        title1: "车主资料",
        title2: "驾驶者资料"
    },

    step8: {
        title: "确认投保资料"
    },

    contact: {
        title: "联络资料",
        name: "联络人姓名",
        email: "电邮地址",
        mobile: "手机号码",
        desc: "*暂保单将透过电邮传送",
        agree_1: "本人/我们确认已阅读并理解贵公司的",
        agree_2: "条款和条件",
        agree_3: "声明。",
        agree_4: "收集个人资料",
        agree_5: "本人同意接受其他产品的推广优惠及资讯。",

        verify: {
            name: "请输入联络人姓名",
            email: "请输入正确的电邮地址",
            mobile: "请输入正确的电话号码"
        }
    },

    info: {
        title: "汽车保险",
        detail: "投保资料",
        type: "保险类别",
        brand: "品牌",
        model: "汽车型号",
        petrol: "汽油类型",
        carType: "汽车类别",
        money: "全保金额",
        quality: "载重吨数",
        year: "出厂年份",
        discount: "无索偿折扣",
        limit: "第三者财物损毁限额",
        date: "起保日期",
        oldNote: "往年保单或续保通知书（如有）",
        financeCompany: "财务公司（如有）",
        oldCompany: "前保险公司名称",
        notPayYears: "没有索偿的连续年数",
        carNum: "车牌号码",
        noteNum: "保单号码",
        isNew: "全新车辆",
        vehicleCert: "车辆登记证",
        chassisNum: "底盘号码",
        engineNum: "引擎号码",
        displacement: "车辆排量",
        bodyType: "车身类型",
        siteNum: "座位数量（不包括司机）",
        setterType: "投保人性质",
        paymentMethod: "选择付款方式",

        edit: "修改资料",
        continue: "继续投保",
        back: "返回",
        next: "下一步",
        state: "私隐政策",
        trem: "条款及细则",
        stateShow: "收集个人资料声明",
        onlyEn: "只提供英文版本",

        owner: {
            id: "香港身份证号码",
            hkid: "香港身份证文件",
            job: "职业",
            isDriver: "车主是否驾驶者之一",
            trade: "行业",
            bizCert: "商业登记证",
            birthDay: "出生日期",
            sex: "性别"
        },

        driver: {
            title: "驾驶者",
            noteNum: "驾驶执照号码",
            note: "驾驶执照文件",
            years: "驾驶年资",
            relation: "与投保人关系",
            add: "驾驶者资料"
        },

        options: {
            type: {
                petrol: "汽油",
                oil: "柴油"
            },

            sex: {
                female: "女",
                male: "男"
            },

            setterType: {
                person: "个人",
                company: "公司"
            },

            relation: {
                t0: "本人",
                t1: "配偶",
                t2: "子女",
                t3: "亲戚",
                t4: "朋友",
                t5: "雇员",
                t6: "雇主",
                t7: "同居伴侣"
            }
        },

        verify: {
            type: "请输入保险类别",
            brand: "请输入品牌",
            model: "请输入汽车型号",
            petrol: "请选择汽油类型",
            carType: "请选择汽车类别",
            money: "请输入全保金额",
            quality: "请输入载重吨数",
            year: "请选择出厂年份",
            discount: "请选择无索偿折扣",
            limit: "请选择第三者财物损毁限额",
            date: "请选择起保日期",
            isNew: "请选择是否全新车辆",
            chassisNum: "请输入底盘号码",
            engineNum: "请输入引擎号码",
            displacement: "请输入车辆排量",
            bodyType: "请输入车身类型",
            carNum: "请输入车牌号码",
            siteNum: "请输入座位数量",
            setterType: "请选择投保人性质",
            id: "请输入正确的身份证号码",
            hkid: "请上载香港身份证文件",
            job: "请选择职业",
            isDriver: "请选择是否驾驶者",
            driver: "请上载驾驶执照文件",
            driverNum: "请输入驾驶执照号码",
            ownerYears: "请填写驾驶年资",
            trade: "请选择行业",
            birthDay: "请选择出生日期",
            sex: "请选择性别",
            oldCompany: "请输入前保险公司名称",
            notPayYears: "请输入没有索偿的连续年数",
            noteNum: "请输入保单号码",
            vehicleCert: "请上载车辆登记证",
            bizCert: "请上载商业登记证",
            relation: "请选择与投保人关系",


            agree1: "请先确认已阅读并理解条款和条件声明。",
            agree2: "请先确认已阅读并理解收集个人资料声明。"
        }
    },

    detail: {

    }
};

export default zhCN;