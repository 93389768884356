const zhHK = {
    logging: "Logging in...",
    loginError: "Login failed, please try again",
    yes: "Yes",
    no: "No",
    ocring: "OCR Loading...",
    tips: "Note",
    close: "Close",

    error: {
        file: "Please select the correct format file (JPG PNG PDF)"
    },

    header: {
        about: "About Us",
        introduction: "Company Introduction",
        partner: "Partner",
        quote: "Insurance Quote",
        claim: "Renovation Insurance",
        domestic: "Domestic Helper Insurance",
        home: "Home Insurance",
        travel: "Travel Insurance",
        owner: "Zhi Le Bao",
        expressTravel: "Express Travel Insurance",
        helperTravel: "Express Domestic Helper Insurance",
        bziPartner: "Business Partner",
        konwledge: "Insurance Knowledge",
        contactUs: "Contact Us",
        login: "Login",
        logout: "Logout",
        hello: "Hello!"
    },

    home: {
        title: "Bicycle Accident Insurance",
        desc: "Bicycle accident insurance insures the medical expenses and losses involved in the accident on the day of your cycling.",
        referer: "referred by the following platform",
        submit: "Insured immediately",
    },

    step: {
        title: "Car Insurance",
        step1: "Fill in the preliminary information",
        step2: "Confirm Quotation",
        step3: "Data declaration",
        step4: "Vehicle Information Declaration",
        step5: "Fill in the insurance information",
        step6: "Fill in the owner and driver information",
        step7: "Confirm the insurance information",
        step8: "Pay online",
        step9: "Complete the insurance application",
        step10: "Finish"
    },

    step1: {
        title: "Preliminary Quotation",
    },

    step2: {
        money: "Premium",
        moneySpec: "(Including premium levy)",
        title1: "Bottom Fee",
        spec1: "Part One-Loss or Damage to the Body",
        spec2: "Part Two-Third Party Legal Liability",

        part1: {
            t1: "General/Body Damage",
            t2: "Theft Loss",
            t3: "Young Driver",
            t4: "Inexperienced driver",
            t5: "Non-designated driver",
            t6: "Parking damaged"
        },

        part2: {
            t1: "Damage to third party property",
            t2: "Young Driver",
            t3: "Inexperienced driver",
            t4: "Non-designated driver"
        }
    },

    step3: {
        title: "Formally insured",
        title1: "Data Declaration",
        spec1: "Have all drivers (including main and other registered drivers) deducted points or are under investigation or prosecution for using vehicles in the past three years?",
        spec2: "Are all drivers (including main and other registered drivers) suffering from sight, hearing impairment or any physical disability?",
        spec3: "Have all drivers (including main and other registered drivers) refused to apply for car insurance, refused to renew, or have special terms attached to the renewal?",
        spec4: "Have all drivers (including the main and other registered drivers) have had accidents, caused losses, or demanded compensation due to the use of vehicles in the past three years?"
    },

    step4: {
        title: "Vehicle Information Declaration",
        spec1: "Has the vehicle been modified or modified? (If yes, please specify all modification details)",
        spec2: "Will the vehicle be leased to a third party for passenger/cargo use?",
        spec3: "Will the vehicle be used for teaching driving?",
        spec4: "Will the vehicle be used for the purpose of selling vehicles?",
        spec5: "Does the vehicle have an anti-theft device installed? (If yes, please provide equipment information)",

        error: {
            theft: "Please fill in the information of the anti-theft device you installed"
        }
    },

    step5: {
        upload: "Click to select file",
        uploading: "Uploading, please wait...",
        history: "Past insurance records"
    },

    step6: {
        title: "Vehicle Information",
        title1: "Car Owner Information",
        title2: "Driver Information"
    },

    step8: {
        title: "Confirm the insurance information"
    },

    contact: {
        title: "Contact Information",
        name: "Contact person's name",
        email: "Email Address",
        mobile: "Mobile Number",
        desc: "*Cover policy will be sent by email",
        agree_1: "I/we confirm that I have read and understood your company",
        agree_2: "Terms and Conditions",
        agree_3: "Agreement.",
        agree_4: "Collect personal data",
        agree_5: "I agree to accept other product promotion offers and information.",

        verify: {
            name: "Please enter the name of the contact person",
            email: "Please enter the correct email address",
            mobile: "Please enter the correct phone number"
        }
    },

    info: {
        title: "Car Insurance",
        detail: "Insured Information",
        type: "Insurance Type",
        brand: "Brand",
        model: "Car Model",
        petrol: "Gasoline Type",
        carType: "Car Type",
        money: "Full Coverage Amount",
        quality: "DWT",
        year: "Factory Year",
        discount: "No Claim Discount",
        limit: "The third party property damage limit",
        date: "Warranty Start Date",
        oldNote: "Previous year policy or renewal notice (if any)",
        financeCompany: "Finance Company (if any)",
        oldCompany: "Former Insurance Company Name",
        notPayYears: "The number of consecutive years without a claim",
        carNum: "License Plate Number",
        noteNum: "Policy Number",
        isNew: "Brand new vehicle",
        vehicleCert: "Vehicle Registration Certificate",
        chassisNum: "Chassis Number",
        engineNum: "Engine Number",
        displacement: "Vehicle Displacement",
        bodyType: "Body Type",
        siteNum: "Number of seats (excluding driver)",
        setterType: "Nature of Policyholder",
        paymentMethod: "Select payment method",

        edit: "Modify Information",
        continue: "Continue to insure",
        back: "Return",
        next: "Next",
        state: "Privacy Policy",
        trem: "Terms and Conditions",
        stateShow: "Personal Information Collection Statement",
        onlyEn: "Only available in English",

        owner: {
            id: "Hong Kong Identity Card Number",
            hkid: "Hong Kong Identity Card Document",
            job: "Occupation",
            isDriver: "Is the owner one of the drivers",
            trade: "Industry",
            bizCert: "Business Registration Certificate",
            birthDay: "Date of Birth",
            sex: "Sex"
        },

        driver: {
            title: "Driver",
            noteNum: "Driver License Number",
            note: "Driver License Document",
            years: "Driving Years",
            relation: "Relationship with the policyholder",
            add: "Driver Information"
        },

        options: {
            type: {
                petrol: "Gasoline",
                oil: "Diesel"
            },

            sex: {
                female: "女",
                male: "Male"
            },

            setterType: {
                person: "personal",
                company: "Company"
            },

            relation: {
                t0: "I",
                t1: "Spouse",
                t2: "Children",
                t3: "relatives",
                t4: "Friends",
                t5: "Employee",
                t6: "Employer",
                t7: "Cohabitation partner"
            }
        },

        verify: {
            detail: "Please select the question option on this page correctly",
            type: "Please enter the insurance type",
            brand: "Please enter the brand",
            model: "Please enter the car model",
            petrol: "Please select gasoline type",
            carType: "Please select a car type",
            money: "Please enter the full insurance amount",
            quality: "Please enter the deadweight tonnage",
            year: "Please select the year of manufacture",
            discount: "Please select no claim discount",
            limit: "Please select the third party property damage limit",
            oldNote: "Please submit this item",
            date: "Please select the starting date",
            isNew: "Please choose whether the vehicle is brand new",
            chassisNum: "Please enter the chassis number",
            engineNum: "Please enter the engine number",
            displacement: "Please enter vehicle displacement",
            bodyType: "Please enter the body type",
            carNum: "Please enter the license plate number",
            siteNum: "Please enter the number of seats",
            setterType: "Please select the nature of the policyholder",
            id: "Please enter the correct ID number",
            hkid: "Please upload Hong Kong ID card documents",
            job: "Please select a career",
            isDriver: "Please choose whether to drive or not",
            driver: "Please upload a driver's license document",
            driverNum: "Please enter the driver's license number",
            ownerYears: "Please fill in the driving experience",
            trade: "Please select an industry",
            birthDay: "Please select the date of birth",
            sex: "Please select gender",
            oldCompany: "Please enter the name of the former insurance company",
            notPayYears: "Please enter the number of consecutive years without a claim",
            noteNum: "Please enter the policy number",
            vehicleCert: "Please upload the vehicle registration certificate",
            bizCert: "Please upload business registration certificate",
            relation: "Please select the relationship with the insured person",
            driverRequire: "Please add at least one driver",


            agree1: "Please confirm that you have read and understood the terms and conditions statement.",
            agree2: "Please confirm that you have read and understood the personal information collection statement."
        }
    },

    detail: {
        t0: "I confirm that the above information is correct.",
        t1: "I have read and agree to Dah Sing Insurance's personal data collection statement.",
        t2: "I authorize Dah Sing Insurance to request the relevant insurance claim records from Zhaoxiang Insurance Company on my behalf.",
        t3: "I agree to Dah Sing Insurance's use of my personal data for promotional information and direct marketing purposes.",
        agree: "Please confirm terms and conditions",
        clear: "Erase",
        undo: "Undo",
        signEmpty: "Please sign in the signature file"
    }
};

export default zhHK;