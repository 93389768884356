<template>
  <Step :step="2" />

  <div class="page container">
    <div class="box">
      <HeaderStep :title="$t('step1.title')" :step="1"></HeaderStep>

      <div class="result">
        {{ $t("step2.money") }}<span class="money">HK$ {{ amount }}</span>
        <span class="tips">{{ $t("step2.moneySpec") }}</span>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("step2.title1") }}</div>
        <div class="detail-group-desc">{{ $t("step2.spec1") }}</div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t1") }}</span>
          <i>HK$ {{ data.detail.money &lt;= 250000 ? "5,000" : "7,500" }} </i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t2") }}</span>
          <i>HK$ {{ data.detail.money &lt;= 250000 ? "5,000" : "7,500" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t3") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t4") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t6") }}</span>
          <i>HK$ 2,000</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">{{ $t("step2.spec2") }}</div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t1") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t2") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t3") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("info.detail") }}</div>
        <div class="detail-item">
          <span>{{ $t("info.type") }}</span>
          <i>{{ data.detail.type }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.brand") }}</span>
          <i>{{ data.detail.brand }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.model") }}</span>
          <i>{{ data.detail.model }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.petrol") }}</span>
          <i>{{ data.detail.petrol }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.carType") }}</span>
          <i>{{ data.detail.car_type }}</i>
        </div>

        <div class="detail-item">
          <span>{{ $t("info.money") }}</span>
          <i>HK$ {{ moneyFormat(data.detail.money) }}</i>
        </div>

        <div class="detail-item">
          <span>{{ $t("info.quality") }}</span>
          <i>{{ data.detail.quality }}</i>
        </div>

        <div class="detail-item">
          <span>{{ $t("info.year") }}</span>
          <i>{{ data.detail.year }}</i>
        </div>

        <div class="detail-item">
          <span>{{ $t("info.discount") }}</span>
          <i>{{ data.detail.discount }}</i>
        </div>

        <div class="detail-item">
          <span>{{ $t("info.limit") }}</span>
          <i>HK$ {{ moneyFormat(data.detail.limit) }}</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("contact.title") }}</div>
        <div class="detail-item">
          <span>{{ $t("contact.name") }}</span>
          <i>{{ data.contact.name }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("contact.email") }}</span>
          <i>{{ data.contact.email }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("contact.mobile") }}</span>
          <i>{{ data.contact.mobile }}</i>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">{{ $t("info.edit") }}</button>
      <button class="btn btn-next" @click="next">
        {{ $t("info.continue") }}
      </button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";

export default {
  name: "Step2",

  components: {
    Step,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      moneyFormat: util.moneyFormat,

      amount: 0,

      data: {
        detail: {
          type: "汽車全保",
          brand: "KIA",
          model: "Sorento",
          petrol: "汽油",
          car_type: "私人車輛",
          money: "",
          quality: "",
          year: "2005",
          discount: "0%",
          limit: "750000",
        },

        contact: {
          name: "",
          email: "",
          mobile: "",
          agree1: true,
          agree2: true,
          agree3: false,
        },
      },

      visibleTerms: false,
      visibleState: false,
    };
  },

  methods: {
    init() {
      this.data = Object.assign(this.data, this.cache);
      this.amount = util.getAmount(this.data);
    },

    prev() {
      this.$router.push("/step1");
    },

    next() {
      this.$router.push("/step3");
    },
  },
};
</script>
<style scoped lang="less">
</style>