<template>
  <Step :step="7" />

  <div class="page container">
    <div class="box">
      <HeaderStep :title="$t('step3.title')" :step="2"></HeaderStep>

      <div class="detail-header">{{ $t("step8.title") }}</div>

      <div class="result">
        {{ $t("step2.money") }}<span class="money">HK$ {{ amount }}</span>
        <span class="tips">{{ $t("step2.moneySpec") }}</span>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("step2.title1") }}</div>
        <div class="detail-group-desc">{{ $t("step2.spec1") }}</div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t1") }}</span>
          <i>HK$ {{ data.detail.money &lt;= 250000 ? "5,000" : "7,500" }} </i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t2") }}</span>
          <i>HK$ {{ data.detail.money &lt;= 250000 ? "5,000" : "7,500" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t3") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t4") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part1.t6") }}</span>
          <i>HK$ 2,000</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">{{ $t("step2.spec2") }}</div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t1") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t2") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("step2.part2.t3") }}</span>
          <i>HK$ {{ data.detail.discount == "0%" ? "7,500" : "5,000" }}</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("info.detail") }}</div>
        <div class="detail-group-desc">
          {{ $t("step.step3") }}
          <router-link to="/step3?confirm=1" class="btn-link">
            {{ $t("info.edit") }}
          </router-link>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>1.</span>{{ $t("step3.spec1") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>2.</span>{{ $t("step3.spec2") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>3.</span>{{ $t("step3.spec3") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>4.</span>{{ $t("step3.spec4") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">
          {{ $t("step6.title") }}
          <router-link to="/step4?confirm=1" class="btn-link">
            {{ $t("info.edit") }}
          </router-link>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>1.</span>{{ $t("step4.spec1") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>2.</span>{{ $t("step4.spec2") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>3.</span>{{ $t("step4.spec3") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item">
          <div class="q-ask"><span>4.</span>{{ $t("step4.spec4") }}</div>
          <div class="q-answer">
            <span>{{ $t("yes") }}</span>
            <span class="active">{{ $t("no") }}</span>
          </div>
        </div>

        <div class="q-item form-group">
          <div class="q-ask"><span>5.</span>{{ $t("step4.spec5") }}</div>
          <div class="q-answer">
            <span :class="{ active: data.car_info_question.q5 }">
              {{ $t("yes") }}
            </span>
            <span :class="{ active: !data.car_info_question.q5 }">
              {{ $t("no") }}
            </span>
          </div>
          <div class="q-spec" v-if="data.car_info_question.q5">
            {{ data.detail.theft }}
          </div>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">
          {{ $t("info.noteDetail") }}
          <router-link to="/step5?confirm=1" class="btn-link">
            {{ $t("info.edit") }}
          </router-link>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.type") }}</span>
          <i>{{ data.detail.type }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.date") }}</span>
          <i>{{ data.detail.date }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.money") }}</span>
          <i>HK$ {{ moneyFormat(data.detail.money) }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.discount") }}</span>
          <i>{{ data.detail.discount }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.limit") }}</span>
          <i>HK$ {{ moneyFormat(data.detail.limit) }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.petrol") }}</span>
          <i>{{ data.detail.petrol }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.carType") }}</span>
          <i>{{ data.detail.car_type }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.oldNoteNoSpec") }}</span>
          <i v-if="data.detail.old_note">
            <a target="_blank" :href="'/storage/' + data.detail.old_note">
              {{ data.detail.old_note_filename }}
            </a>
          </i>
          <i v-else>N.A.</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.financeCompany") }}</span>
          <i>
            {{
              data.detail.finance_company ? data.detail.finance_company : "N.A."
            }}
          </i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">
          {{ $t("step5.history") }}
        </div>
        <div class="detail-item">
          <span>{{ $t("info.oldCompany") }}</span>
          <i>{{ data.detail.old_company }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.notPayYears") }}</span>
          <i>{{ data.detail.not_pay_years }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.carNum") }}</span>
          <i>{{ data.detail.car_old_num }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.noteNum") }}</span>
          <i>{{ data.detail.note_num }}</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">
          {{ $t("step6.title") }}
          <router-link to="/step6?confirm=1" class="btn-link">
            {{ $t("info.edit") }}
          </router-link>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.isNew") }}</span>
          <i>{{ data.detail.is_new ? $t("yes") : $t("no") }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.vehicleCert") }}</span>
          <i>
            <a target="_blank" :href="'/storage/' + data.detail.vehicle_cert">
              {{ data.detail.vehicle_cert_filename }}
            </a>
          </i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.brand") }}</span>
          <i>{{ data.detail.brand }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.model") }}</span>
          <i>{{ data.detail.model }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.chassisNum") }}</span>
          <i>{{ data.detail.chassis_num }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.engineNum") }}</span>
          <i>{{ data.detail.engine_num }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.displacement") }}</span>
          <i>{{ data.detail.displacement }} cc</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.bodyType") }}</span>
          <i>{{ data.detail.body_type }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.quality") }}</span>
          <i>{{ data.detail.quality }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.carNum") }}</span>
          <i>{{ data.detail.car_num }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.year") }}</span>
          <i>{{ data.detail.year }}</i>
        </div>
        <div class="detail-item">
          <span>{{ $t("info.siteNum") }}</span>
          <i>{{ data.detail.site_num }}</i>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-desc">
          {{ $t("step6.title1") }}
          <router-link to="/step7?confirm=1" class="btn-link">
            {{ $t("info.edit") }}
          </router-link>
        </div>

        <template v-if="data.detail.setter_type == 'person'">
          <div class="detail-item">
            <span>{{ $t("info.owner.name") }}</span>
            <i>{{ data.detail.owner.name }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.birthDay") }}</span>
            <i>{{ data.detail.owner.birth_day }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.sex") }}</span>
            <i>
              {{
                data.detail.owner.sex == "M"
                  ? $t("info.options.sex.male")
                  : $t("info.options.sex.female")
              }}
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.id") }}</span>
            <i>{{ data.detail.owner.id }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.hkid") }}</span>
            <i>
              <a target="_blank" :href="'/storage/' + data.detail.owner.hkid">
                {{ data.detail.owner.hkid_filename }}
              </a>
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.job") }}</span>
            <i>
              {{
                data.detail.owner.job ? jobs[data.detail.owner.job].label : ""
              }}
            </i>
          </div>
        </template>

        <template
          v-if="
            data.detail.owner.is_driver && data.detail.setter_type == 'person'
          "
        >
          <div class="detail-item">
            <span>{{ $t("info.driver.noteNum") }}</span>
            <i>{{ data.detail.owner.driver_num }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.driver.note") }}</span>
            <i>
              <a target="_blank" :href="'/storage/' + data.detail.owner.driver">
                {{ data.detail.owner.driver_filename }}
              </a>
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.driver.years") }}</span>
            <i>{{ data.detail.owner.years }}</i>
          </div>
        </template>

        <template v-if="data.detail.setter_type == 'company'">
          <div class="detail-item">
            <span>{{ $t("info.owner.companyName") }}</span>
            <i>{{ data.detail.owner.company_name }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.trade") }}</span>
            <i>
              {{
                data.detail.owner.trade
                  ? jobs[data.detail.owner.trade].label
                  : ""
              }}
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.bizCertNum") }}</span>
            <i>{{ data.detail.owner.biz_cert_num }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.bizCert") }}</span>
            <i>
              <a
                target="_blank"
                :href="`/storage/${data.detail.owner.biz_cert}`"
              >
                {{ data.detail.owner.biz_cert_filename }}
              </a>
            </i>
          </div>
        </template>
      </div>

      <div class="detail-group" v-if="data.drivers.length > 0">
        <div class="detail-group-desc">
          {{ $t("info.driver.add") }}
        </div>
        <template v-for="(driver, index) in data.drivers" :key="index">
          <div class="empty-line"></div>
          <div class="detail-group-desc">
            {{ $t("info.driver.title") }}{{ index + 1 }}
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.birthDay") }}</span>
            <i>{{ driver.birth_day }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.sex") }}</span>
            <i>
              {{
                driver.sex == "M"
                  ? $t("info.options.sex.male")
                  : $t("info.options.sex.female")
              }}
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.id") }}</span>
            <i>{{ driver.id }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.hkid") }}</span>
            <i>
              <a target="_blank" :href="'/storage/' + driver.hkid">
                {{ driver.hkid_filename }}
              </a>
            </i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.owner.job") }}</span>
            <i>{{ driver.job ? jobs[driver.job].label : "" }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.driver.years") }}</span>
            <i>{{ driver.years }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.driver.note") }}</span>
            <i>{{ driver.note_filename }}</i>
          </div>
          <div class="detail-item">
            <span>{{ $t("info.driver.relation") }}</span>
            <i>{{ driver.relation }}</i>
          </div>
        </template>
      </div>

      <div class="detail-group checkbox-wrap">
        <div class="detail-group-title">{{ $t("info.trem") }}</div>
        <div class="detail-item">
          <input
            type="checkbox"
            v-model="agree0"
            name="agree0"
            id="agree0"
            value="1"
          />
          <label for="agree0">{{ $t("detail.t0") }}</label>
        </div>
        <div class="detail-item">
          <input
            type="checkbox"
            v-model="agree1"
            name="agree1"
            id="agree1"
            value="1"
          />
          <label for="agree1">{{ $t("detail.t1") }}</label>
        </div>
        <div class="detail-item">
          <input
            type="checkbox"
            v-model="agree2"
            name="agree2"
            id="agree2"
            value="1"
          />
          <label for="agree2">{{ $t("detail.t2") }}</label>
        </div>
        <div class="detail-item">
          <input
            type="checkbox"
            v-model="agree3"
            name="agree3"
            id="agree3"
            value="1"
          />
          <label for="agree3">{{ $t("detail.t3") }}</label>
        </div>
      </div>

      <div class="detail-group">
        <div class="detail-group-title">{{ $t("info.signature") }}</div>
        <div class="detail-item">
          <canvas
            id="signature-pad"
            class="signature-pad"
            width="400"
            height="200"
            ref="sign"
          ></canvas>
          <div class="sign-action">
            <a class="clear" @click="clearSign">{{ $t("detail.clear") }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">{{ $t("info.back") }}</button>
      <button class="btn btn-next" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>
</template>
<script>
import SignaturePad from "signature_pad";

import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";
import jobs from "../../data/jobs";

export default {
  name: "Step8",

  components: {
    Step,
    HeaderStep,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      amount: 0,

      signaturePad: null,

      moneyFormat: util.moneyFormat,

      jobs,

      agree0: true,

      agree1: true,

      agree2: true,

      agree3: true,

      data: {
        detail: {
          type: "汽車全保",
          brand: "KIA",
          model: "Sorento",
          petrol: "汽油",
          car_type: "私人車輛",
          money: "",
          quality: "",
          year: "2005",
          discount: "0%",
          limit: "750000",
          date: "",
          old_note: "",
          finance_company: "",
          old_company: "",
          not_pay_years: "",
          car_old_num: "",
          note_num: "",
          is_new: 0,
          vehicle_cert: "",
          chassis_num: "",
          engine_num: "",
          displacement: "",
          body_type: "",
          car_num: "",
          site_num: "",
          setter_type: "person",

          owner: {
            hkid: "",
            job: "",
            is_driver: 1,
            driver: "",
            years: 0,
            trade: "",
            biz_cert: "",
          },
        },

        contact: {
          name: "",
          email: "",
          mobile: "",
          agree1: true,
          agree2: true,
          agree3: false,
        },

        drivers: [
          {
            hkid: "",
            job: "",
            note: "",
            years: "",
            relation: "",
          },
        ],

        car_info_question: {
          q1: false,
          q2: false,
          q3: false,
          q4: false,
          q5: false,
        },
      },

      visibleTerms: false,
      visibleState: false,
    };
  },

  methods: {
    init() {
      this.data = Object.assign(this.data, this.cache);
      this.amount = util.getAmount(this.data);

      this.initSignPad();
    },

    initSignPad() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.$refs.sign.width = this.$refs.sign.offsetWidth * ratio;
      this.$refs.sign.height = this.$refs.sign.offsetHeight * ratio;
      this.$refs.sign.getContext("2d").scale(ratio, ratio);

      this.signaturePad = new SignaturePad(this.$refs.sign, {
        backgroundColor: "rgb(246, 246, 246)",
      });

      const oldSign = this.cache.sign;
      if (oldSign) {
        this.signaturePad.fromDataURL(oldSign);
      }
    },

    clearSign() {
      this.signaturePad.clear();
      return false;
    },

    undoSign() {
      let data = this.signaturePad.toData();
      if (data) {
        data.pop();
        this.signaturePad.fromData(data);
      }
      return false;
    },

    prev() {
      this.$router.push("/step7");
    },

    next() {
      if (!this.agree0 || !this.agree1 || !this.agree2) {
        alert(this.$t("detail.agree"));
        return;
      }

      if (this.signaturePad.isEmpty()) {
        alert(this.$t("detail.signEmpty"));
        return;
      }

      this.$store.commit(
        "detail/setSignature",
        this.signaturePad.toDataURL("image/jpeg")
      );
      this.$store.commit("detail/setAmount", this.amount);
      this.$store.commit("detail/setCache");

      this.$router.push("/payment");
    },
  },
};
</script>
<style scoped lang="less">
.detail-header {
  margin: 0px 0 16px 0;
  font-weight: bold;
  font-size: 26px;
  color: #007553;
}

.btn-link {
  margin: 0 0 0 20px;
  padding: 2px;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #0000ff;
  color: #00f;
  border-radius: 5px;
}

.empty-line {
  margin-top: 20px;
}

.signature-pad {
  background: #f6f6f6;
}

.sign-action {
  a {
    cursor: pointer;
    margin-right: 10px;
  }
}

.checkbox-wrap {
  .detail-item {
    padding: 0 0 0 25px;
    margin-bottom: 8px;
    position: relative;

    label {
      cursor: pointer;
    }

    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: 0;
    }

    * {
      vertical-align: bottom;
    }
  }
}

.clear {
  width: 66px;
  height: 40px;
  display: block;
  background: #f6f6f6;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
}

@media screen and (max-width: 770px) {
  .signature-pad {
    width: 100%;
    height: 300px;
  }
}
</style>