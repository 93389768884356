<template>
  <Step :step="5" />

  <div class="info container page">
    <div class="box">
      <div class="form-group" id="s1">
        <HeaderStep :title="$t('step3.title')" :step="2"></HeaderStep>

        <div class="form-group-title">{{ $t("info.detail") }}</div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.type }" id="s2">
            <label class="form-field">
              {{ $t("info.type") }}
              <span v-if="error.type">{{ error.type }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.type"
              @blur="check('type')"
              readonly
            />
          </div>

          <div class="form-item" :class="{ error: error.date }" id="s3">
            <label class="form-field">
              {{ $t("info.date") }}
              <span v-if="error.date">{{ error.date }}</span>
            </label>
            <flatPickr
              :config="Object.assign({ maxDate: 'today' }, config)"
              class="form-input"
              @on-change="check('date')"
              v-model="detail.date"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.money }" id="s4">
            <label class="form-field">
              {{ $t("info.money") }}
              <span v-if="error.money">{{ error.money }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.money"
              @blur="check('money')"
              type="number"
            />
          </div>

          <div class="form-item" :class="{ error: error.discount }" id="s5">
            <label class="form-field">
              {{ $t("info.discount") }}
              <span v-if="error.discount">{{ error.discount }}</span>
            </label>

            <Multiselect
              v-if="detail.petrol == '柴油'"
              class="form-input"
              v-model="detail.discount"
              :options="options.discount1"
              @select="check('discount')"
              :allowEmpty="false"
            />
            <Multiselect
              v-else
              class="form-input"
              v-model="detail.discount"
              :options="options.discount2"
              @select="check('discount')"
              :allowEmpty="false"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.petrol }" id="s6">
            <label class="form-field">
              {{ $t("info.petrol") }}
              <span v-if="error.petrol">{{ error.petrol }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.petrol"
              :options="options.petrol"
              @select="selectPetrol"
              :allowEmpty="false"
            />
          </div>

          <div class="form-item" :class="{ error: error.carType }" id="s7">
            <label class="form-field">
              {{ $t("info.carType") }}
              <span v-if="error.carType">{{ error.carType }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.car_type"
              @blur="check('carType')"
              readonly
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.limit }" id="s8">
            <label class="form-field">
              {{ $t("info.limit") }}
              <span v-if="error.limit">{{ error.limit }}</span>
            </label>
            <Multiselect
              class="form-input"
              v-model="detail.limit"
              :options="options.limit"
              @select="check('limit')"
              :allowEmpty="false"
            />
          </div>

          <div class="form-item" :class="{ error: error.oldNote }" id="s9">
            <label class="form-field">
              {{ $t("info.oldNote") }}
              <span v-if="error.oldNote">{{ error.oldNote }}</span>
            </label>
            <file-upload
              v-model="files"
              :post-action="api"
              @input-file="inputFile"
              @input-filter="inputFilter"
              ref="upload"
            >
              <div class="form-input" v-if="!detail.old_note_filename">
                {{ $t("step5.upload") }}
              </div>
              <div class="form-input" v-else>
                {{ detail.old_note_filename }}
              </div>
            </file-upload>
          </div>
        </div>

        <div class="form-line">
          <div
            class="form-item"
            :class="{ error: error.financeCompany }"
            id="s10"
          >
            <label class="form-field">
              {{ $t("info.financeCompany") }}
              <span v-if="error.financeCompany">
                {{ error.financeCompany }}
              </span>
            </label>
            <input
              class="form-input"
              v-model="detail.finance_company"
              @blur="check('financeCompany')"
            />
          </div>
        </div>

        <div class="form-group-title">{{ $t("step5.history") }}</div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.oldCompany }" id="s11">
            <label class="form-field">
              {{ $t("info.oldCompany") }}
              <span v-if="error.oldCompany">{{ error.oldCompany }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.old_company"
              @blur="check('oldCompany')"
            />
          </div>

          <div class="form-item" :class="{ error: error.notPayYears }" id="s12">
            <label class="form-field">
              {{ $t("info.notPayYears") }}
              <span v-if="error.notPayYears">{{ error.notPayYears }}</span>
            </label>
            <input
              class="form-input"
              type="number"
              v-model="detail.not_pay_years"
              @blur="check('notPayYears')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.carNum }" id="s13">
            <label class="form-field">
              {{ $t("info.carNum") }}
              <span v-if="error.carNum">{{ error.carNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.car_old_num"
              @blur="check('carNum')"
            />
          </div>
          <div class="form-item" :class="{ error: error.noteNum }">
            <label class="form-field">
              {{ $t("info.noteNum") }}
              <span v-if="error.noteNum">{{ error.noteNum }}</span>
            </label>
            <input
              class="form-input"
              v-model="detail.note_num"
              @blur="check('noteNum')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">{{ $t("info.back") }}</button>
      <button class="btn btn-next" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";
import Multiselect from "@vueform/multiselect";

import FileUpload from "vue-upload-component";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw";
import { Mandarin } from "flatpickr/dist/l10n/zh";

import "@vueform/multiselect/themes/default.css";

import config from "../../config";

import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Step5",

  components: {
    Step,
    Multiselect,
    HeaderStep,
    flatPickr,
    FileUpload,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      api: config.api + "file",

      config: {
        disable: ["2100-01-01"],
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        locale: localStorage.lang == "zhHK" ? MandarinTraditional : Mandarin,
        minDate: "today",
        maxDate: (() => {
          const month = new Date().getMonth() + 3;
          const year = new Date().getFullYear();
          const day = new Date().getDate() - 1;

          /*
          let day = "";
          
          if (
            (month <= 7 && month % 2 != 0) ||
            (month >= 8 && month % 2 == 0)
          ) {
            day = 31;
          } else {
            day = 30;
          }
          */

          return year + "-" + month + "-" + day;
        })(),
      },

      detail: {
        type: "汽車全保",
        brand: "KIA",
        model: "Sorento",
        petrol: "汽油",
        car_type: "私人車輛",
        money: "",
        quality: "",
        year: "2005",
        discount: "0%",
        limit: "750000",
        date: "",
        old_note: "",
        old_note_filename: "",
        finance_company: "",
        old_company: "",
        not_pay_years: "",
        car_old_num: "",
        note_num: "",
      },

      contact: {
        name: "",
        email: "",
        mobile: "",
        agree1: true,
        agree2: true,
        agree3: false,
      },

      options: {
        petrol: [
          this.$t("info.options.type.petrol"),
          this.$t("info.options.type.oil"),
        ],

        years: (() => {
          let years = [];
          for (let i = 1970; i <= 2005; i++) {
            years.push(i + "");
          }
          return years;
        })(),

        discount1: ["0%", "10%", "20%", "30%"],

        discount2: ["0%", "20%", "30%", "40%", "50%", "60%"],

        limit: [
          { value: "750000", label: "750,000" },
          { value: "2000000", label: "2,000,000" },
        ],
      },

      error: {
        type: false,
        date: false,
        money: false,
        discount: false,
        petrol: false,
        carType: false,
        limit: false,
        oldNote: false,
        financeCompany: false,
        oldCompany: false,
        notPayYears: false,
        carNum: false,
        noteNum: false,
      },

      files: [],
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache.detail);
    this.contact = Object.assign(this.contact, this.cache.contact);
  },

  methods: {
    selectPetrol(value) {
      this.detail.discount = null;

      this.detail.car_type = value == "汽油" ? "私人車輛" : "商用車輛";

      this.detail.limit = value == "汽油" ? "2000000" : "750000";

      this.options.limit =
        value == "汽油"
          ? [{ value: "2000000", label: "HK$ 2,000,000" }]
          : [
              { value: "750000", label: "HK$ 750,000" },
              { value: "2000000", label: "HK$ 2,000,000" },
            ];

      this.check("petrol");
    },

    check(field) {
      const self = this;

      let rules = {
        type: {
          model: this.detail.type,
          ref: "#s1",
          message: this.$t("info.verify.type"),
          fn: "require",
        },
        date: {
          model: this.detail.date,
          ref: "#s1",
          message: this.$t("info.verify.date"),
          fn: "require",
        },
        money: {
          model: this.detail.money,
          ref: "#s3",
          message: this.$t("info.verify.money"),
          fn: "number",
        },
        discount: {
          model: this.detail.discount,
          ref: "#s4",
          message: this.$t("info.verify.discount"),
          fn: "require",
        },
        petrol: {
          model: this.detail.petrol,
          ref: "#s5",
          message: this.$t("info.verify.petrol"),
          fn: "require",
        },
        carType: {
          model: this.detail.car_type,
          ref: "#s6",
          message: this.$t("info.verify.carType"),
          fn: "require",
        },
        oldNote: {
          model: this.detail.old_note,
          ref: "#s7",
          message: this.$t("info.verify.oldNote"),
          before() {
            return self.detail.discount != "0%";
          },
          fn: "require",
        },
        limit: {
          model: this.detail.limit,
          ref: "#s1",
          message: this.$t("info.verify.limit"),
          fn: "require",
        },
        financeCompany: {
          model: this.detail.finance_company,
          ref: "#s9",
          message: this.$t("info.verify.financeCompany"),
          before() {
            return self.detail.finance_company.trim() != "";
          },
          fn: "alphaDash",
        },
        oldCompany: {
          model: this.detail.old_company,
          ref: "#s10",
          message: this.$t("info.verify.oldCompany"),
          before() {
            return (
              self.detail.old_company ||
              self.detail.not_pay_years ||
              self.detail.car_old_num ||
              self.detail.note_num
            );
          },
          fn: "alphaDash",
        },
        notPayYears: {
          model: this.detail.not_pay_years,
          ref: "#s11",
          message: this.$t("info.verify.notPayYears"),
          before() {
            return (
              self.detail.old_company ||
              self.detail.not_pay_years ||
              self.detail.car_old_num ||
              self.detail.note_num
            );
          },
          fn: "number",
        },
        carNum: {
          model: this.detail.car_old_num,
          ref: "#s12",
          message: this.$t("info.verify.carNum"),
          before() {
            return (
              self.detail.old_company ||
              self.detail.not_pay_years ||
              self.detail.car_old_num ||
              self.detail.note_num
            );
          },
          fn: "alphaDash",
        },
        noteNum: {
          model: this.detail.note_num,
          ref: "#s13",
          message: this.$t("info.verify.noteNum"),
          before() {
            return (
              self.detail.old_company ||
              self.detail.not_pay_years ||
              self.detail.car_old_num ||
              self.detail.note_num
            );
          },
          fn: "alphaDash",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (rule.before && !rule.before()) {
          this.error[key] = false;
          continue;
        }

        if (!util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    prev() {
      this.$router.push("/step4");
    },

    next() {
      if (!this.check()) {
        return;
      }

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setCache");

      if (this.$route.query.confirm) {
        this.$router.push("/step8");
      } else {
        this.$router.push("/step6");
      }
    },

    inputFile(newFile, oldFile) {
      // Upload progress
      if ((newFile && newFile.progress) || (oldFile && oldFile.progress)) {
        this.detail.old_note_filename = this.$t("step5.uploading");
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }

      if (!newFile || !newFile.success) {
        return false;
      }

      const data = newFile.response.data;

      this.detail.old_note_filename = data.origin_name;
      this.detail.old_note = data.save_name;
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // 过滤不是图片后缀的文件
        if (!/\.(jpeg|jpe|jpg|png|pdf)$/i.test(newFile.name)) {
          alert(this.$t("error.file"));
          return prevent();
        }
      }

      this.$refs.upload.active = true;
    },
  },
};
</script>
<style scoped lang="less">
</style>